import moment from "moment";
import {buildFromFirebase} from "./event";

const colorCodes = {
  orange: "#F4511E",
  gray: "#757575",
  cyan: "#00BCD4",
  brown: "#A1887F",
  blue: "#09f",
  red: "#f05",
  green: "#79d156",
  yellow: "#FDD835",
  purple: "#90f"
};

function isFormationHandle(handle) {
  return handle.toLowerCase().indexOf('csa') >= 0
      || handle.toLowerCase().indexOf('ast') >= 0
      || handle.toLowerCase().indexOf('glacier') >= 0
      || handle.toLowerCase().indexOf('staff') >= 0
      || handle.toLowerCase().indexOf('steep') >= 0
}

function getAutomaticBgColor(event) {
  const {handle, orderNumber, manualOrder, dayoff} = event;

  let bgColor = colorCodes.blue;
  if (dayoff) {
    bgColor = colorCodes.gray;
  } else if (handle) {
    if (handle === "ski-hors-piste-guide" && (orderNumber || manualOrder)) {
      bgColor = "#009e53";
    } else if (isFormationHandle(handle)) {
      bgColor = colorCodes.red;
    } else if (handle.toLowerCase().indexOf('scolaire') >= 0) {
      bgColor = colorCodes.orange;
    } else if (handle.toLowerCase().indexOf('sepaq') >= 0) {
      bgColor = colorCodes.green;
    } else if (handle.toLowerCase().indexOf('mtq') >= 0) {
      bgColor = colorCodes.yellow;
    } else if (handle.toLowerCase().indexOf('bureau') >= 0) {
      bgColor = colorCodes.purple;
    }
  }
  return bgColor;
}

function getBgColorFromColorName(code) {
  if (colorCodes[code]) {
    return colorCodes[code];
  }
  return colorCodes.blue;
}

export function getEventBgColor(event) {
  const {colorName} = event;
  return !colorName || colorName === "auto" ? getAutomaticBgColor(event) : getBgColorFromColorName(colorName);
}

export function getEventShortenTitle(title) {

  if(title) {
    if (title.match(/\(CSA-1\)/gi)) { title = 'CSA-1'; }
    else if (title.match(/\(CSA-2\)/gi)) { title = 'CSA-2'; }
    else if (title.match(/\(CSA-1\+\)/gi)) { title = 'CSA-1+'; }
    else if (title.match(/glacier/gi)) { title = 'Glacier'; }
    else if (title.match(/ski .+ SÉPAQ/gi)) { title = 'Ski | SÉPAQ'; }
    else if (title.match(/raquette .+ SÉPAQ/gi)) { title = 'Raquette | SÉPAQ'; }
  }

  return title;
}

export function getApplicableEventsForDate (date, events) {
  const parsedDate = moment(date, "YYYY-MM-DD HH:mm:ss");
  return events.filter((e) => {
    const start = moment(e.start, "YYYY-MM-DD HH:mm:ss").startOf('day');
    const end = moment(e.end, "YYYY-MM-DD HH:mm:ss").endOf('day');
    return start.isSameOrBefore(parsedDate) && end.isSameOrAfter(parsedDate);
  });
}

export async function fetchFirebaseEvents (database, shopId, enabledProducts, start, end) {
  const startFilter = start.toISOString();
  const endFilter = end.toISOString();

  const snapshot = await database
    .ref(`/events/${shopId}`)
    .orderByChild("start")
    .startAt(startFilter)
    .endAt(endFilter)
    .once("value");
  const dbEvents = snapshot.val();

  let events = [];
  for (let eventId in dbEvents) {
    let eventObj = dbEvents[eventId];
    const event = buildFromFirebase(eventId, eventObj, enabledProducts);
    events.push(event);
  }

  events.sort((a, b) => a.start > b.start ? 1 : -1 );
  return events;
};

export function getNbCustomersForEvent(event) {
  if (event.handle === 'ski-hors-piste-guide' && event.variant) {
    const variantTitle = event.variant.title;
    const nbCustomerStr = variantTitle.replace(/\D+/g, '');
    return parseInt(nbCustomerStr);
  } else if (event.participants && event.participants.length) {
    return event.participants.length;
  }
  return 0;
}
