import React, { useContext, useState, useCallback } from "react";
import { withRouter } from 'react-router-dom';
import { AuthContext } from "../App";
import { Form, FormLayout, Card, Button, TextField, TextStyle } from "@shopify/polaris";
import { auth } from "../firebase";

const SignIn = ({ history }) => {
  const Auth = useContext(AuthContext);
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');
  const [forgottenMode, setForgottenMode] = useState(false);
  const [forgottenEmailSent, setForgottenEmailSent] = useState(false);

  const handleSubmit = () => {
    if (forgottenMode) {
      if (email) {
        auth.sendPasswordResetEmail(email).then(() => {
          setForgottenEmailSent(true);
        }).catch(error => {
          setError("Impossible d'envoyer le courriel de mot de passe oublié.");
        });
      } else {
        setError('Entrer votre courriel');
      }
    } else {
      if (email && password) {
        auth.signInWithEmailAndPassword(email, password).then(() => {
          console.log("Logged in");
          Auth.setLoggedIn(true);
          history.push("/");
        }).catch(error => {
          setError(error.message);
        });
      } else {
        setError('Entrer votre courriel et mot de passe');
      }
    }
  }

  const handleEmailChange = useCallback((value) => setEmail(value), []);
  const handlePasswordChange = useCallback((value) => setPassword(value), []);
  const switchToForgottenMode = () => {
    setError("");
    setForgottenMode(true);
  }

  return (
    <div className="login-page">
      <div className="login-logo"></div>
      <Card>
        <div style={{ width: "360px", padding: '30px 20px' }}>
          {forgottenEmailSent && <TextStyle>Vérifier vos courriels pour réinitialiser votre mot de passe.</TextStyle>}
          {!forgottenEmailSent &&
            <Form onSubmit={handleSubmit}>
              {!forgottenMode &&
                <FormLayout>

                  <TextField
                    value={email}
                    onChange={handleEmailChange}
                    label="Email"
                    type="email"
                  />
                  <TextField
                    value={password}
                    onChange={handlePasswordChange}
                    label="Mot de passe"
                    type="password"
                  />
                  <Button plain onClick={() => switchToForgottenMode()}>Mot de passe oublié?</Button>

                  <TextStyle variation="negative">{error}</TextStyle>

                  <Button primary fullWidth submit>Connexion</Button>
                </FormLayout>
              }

              {forgottenMode &&
                <FormLayout>

                  <TextField
                    value={email}
                    onChange={handleEmailChange}
                    label="Email"
                    type="email"
                  />

                  <TextStyle variation="negative">{error}</TextStyle>

                  <Button primary fullWidth submit>Envoyer</Button>
                </FormLayout>
              }
            </Form>
          }
        </div>
      </Card>
    </div>
  );
};

export default withRouter(SignIn);
