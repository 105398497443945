import { SHOW_TOAST, HIDE_TOAST } from "../actionTypes";

const initialState = {
  visible: false,
  message: "",
  duration: 5000,
};

export default function(state = initialState, action) {
  switch (action.type) {
    case SHOW_TOAST: {
      const { message, duration } = action.payload;
      return {
        ...state,
        visible: true,
        message,
        duration
      }
    }
    case HIDE_TOAST: {
      return {
        ...state,
        visible: false,
        message: "",
        duration: 5000
      }
    }
    default:
      return state;
  }
}
