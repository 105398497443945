import React from "react";
import { Link } from "react-router-dom";
import protectedRoutes from "../protectedRoutes";

function Menu({ path, userRole }) {
  function renderLink(routePath) {
    const route = protectedRoutes.find((r) => r.path === routePath);
    if (route) {
      if (route.allowedRoles && route.allowedRoles.indexOf(userRole)  === -1) {
        return null;
      } else {
        return (
          <div className={routePath === path ? "currentLink" : ""}>
            <Link to={routePath}>{route.name}</Link>
          </div>
        );
      }
    }
  }

  return (
    <div className="menu">
      {renderLink("/")}
      {renderLink("/rapport-jour")}
      {renderLink("/calendrier-locations")}
      {renderLink("/locations-jour")}
      {renderLink("/dates-locations")}
      {renderLink("/activer-produits")}
      {renderLink("/parametres")}
    </div>
  );
}

export default Menu;
