import React from "react";
import {
  Button,
  ButtonGroup,
  Card,
  DataTable,
  Form,
  FormLayout,
  Heading,
  Icon,
  Layout,
  Modal,
  Page,
  Select,
  Spinner,
  Stack,
  TextContainer,
  TextField,
} from "@shopify/polaris";
import { auth, database, functions, DOMAIN_PREFIX } from "../firebase";
import { connect } from "react-redux";
import { showToast } from "../redux/actions";
import moment from "moment";
import {
  ArrowUpMinor, ArrowDownMinor
} from '@shopify/polaris-icons';
import { setUsers } from "../redux/actions";

function sortResources(rA, rB) {
  const raOrder = rA.order || 0;
  const rbOrder = rB.order || 0;
  if (raOrder === rbOrder) {
    return rA.name.toLowerCase().localeCompare(rB.name.toLowerCase());
  }
  return raOrder - rbOrder;
}

class Settings extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      loaded: false,
      confirmDeleteModal: false,
      confirmDeleteId: null,
      newUserName: "",
      newUserRole: "guide",
      newUserEmail: "",
      resources: [],
      updatingUser: {},
    };
  }

  componentDidMount() {
    database.ref("/users/").on("value", this.setResources);
  }

  componentWillUnmount() {
    database.ref("/users/").off();
  }

  setResources = (snapshot) => {
    let resources = snapshot.val();

    let resourcesArray = [];
    for (let key in resources) {
      let obj = resources[key];
      obj.id = key;
      resourcesArray.push(obj);
    }

    resourcesArray.sort(sortResources);

    this.setState({ resources: resourcesArray, loaded: true });
  };

  addResource = () => {
    let { newUserEmail, newUserRole, newUserName } = this.state;

    var createAdminUser = functions.httpsCallable("createAdminUser");
    createAdminUser({
        name: newUserName,
        role: newUserRole,
        email: newUserEmail,
        order: this.state.resources.length + 1
      }).then(() => {
        const email = newUserEmail;
        this.setState({
          newUserEmail: "",
          newUserName: "",
          newUserRole: "guide",
        });

        this.resetPassword(email);
      })
      .catch((error) => {
        console.log(error);
        this.props.showToast(error.message, 7000);
      });
  };

  resetPassword = (email) => {
    auth.sendPasswordResetEmail(email).then(() => {
      this.props.showToast(`Courriel envoyé à ${email}`, 4000);
    }).catch(error => {
      this.props.showToast("Une erreur est survenue", 4000);
    });
  }

  deleteResource = () => {
    const { confirmDeleteId } = this.state;
    if (confirmDeleteId) {
      console.log(confirmDeleteId);
      var deleteAdminUser = functions.httpsCallable("deleteAdminUser");
      deleteAdminUser({ id: confirmDeleteId })
        .then(() => {
          this.setState({
            confirmDeleteModal: false,
            confirmDeleteId: null,
          });
          this.props.showToast("Supprimé avec succès", 4000);
        })
        .catch((error) => {
          console.log(error);
          this.props.showToast(error.message, 7000);
        });
    }
  };

  moveUser = async (resources, item, direction) => {
    const itemIndex = resources.indexOf(item);
    if (direction === 1 && itemIndex === resources.length - 1) {
      return;
    }
    if (direction === -1 && itemIndex === 0) {
      return;
    }

    const swapItem = resources[itemIndex + direction];
    const swap = item.order;
    item.order = swapItem.order;
    swapItem.order = swap;

    const copy = [...resources];
    copy.sort(sortResources);
    this.setState({
      ...this.state,
      resources: copy
    });

    await this.saveSwapResourcesOrder(item, swapItem);

    this.updateUsers(copy);
  }

  updateUsers = (users) => {
    this.props.setUsers(users.reduce((acc, item) => {
      acc[item.id] = item;
      return acc;
    }, {}));
  }

  saveSwapResourcesOrder = (item1, item2) => {
    const save1 = database
      .ref(`/users`)
      .child(item1.id)
      .child("order")
      .set(item1.order);
    const save2 = database
      .ref(`/users`)
      .child(item2.id)
      .child("order")
      .set(item2.order);
    return Promise.all([save1, save2]);
  }

  showUserInCalendar = async (item, show) => {
    const calendarRef = database.ref(`/users`).child(item.id).child("calendar");
    const value = show ? "show" : "hide";
    item.calendar = value;
    await calendarRef.set(value);

    //update the state to refresh the table
    const copy = [...this.state.resources];
    this.setState({
      ...this.state,
      resources: copy
    });

    this.updateUsers(copy);
  }

  changeRole = async (item, newRole) => {
    var changeUserRole = functions.httpsCallable("changeUserRole");
    await changeUserRole({
        id: item.id,
        role: newRole,
      }).then(() => {
        //update the state to refresh the table
        item.role = newRole;
        const copy = [...this.state.resources];
        this.setState({
          ...this.state,
          resources: copy
        });

        this.updateUsers(copy);

        this.props.showToast("Rôle modifié pour " + item.email, 2000);
      })
      .catch((error) => {
        console.log(error);
        this.props.showToast(error.message, 7000);
      });
  }

  setUpdatingUser = (id, updating) => {
    const {updatingUser} = this.state;
    updatingUser[id] = updating;
    this.setState({
      ...this.state,
      updatingUser,
    })
  }

  render() {
    let {
      newUserName,
      newUserEmail,
      newUserRole,
      loaded,
      resources,
      confirmDeleteModal
    } = this.state;

    const roleOptions = [
      { label: "Guide", value: "guide" },
      { label: "Admin", value: "admin" },
    ];

    let rows = [];
    for (let i = 0; i < resources.length; i++) {
      let item = resources[i];
      if (item.id === "unassigned") {
        continue;
      }

      let ordering = (
        <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-around' }}>
          <Button plain onClick={() => this.moveUser(resources, item, -1)}>
            <Icon source={ArrowUpMinor} />
          </Button>
          <Button plain onClick={() => this.moveUser(resources, item, 1)}>
            <Icon source={ArrowDownMinor} />
          </Button>
        </div>
      );

      let actions = (
        <div style={{ display: 'flex', flexDirection: 'column' }}>
          <Button plain destructive onClick={() => this.setState({
            confirmDeleteModal: true,
            confirmDeleteId: item.id
          })}>Supprimer</Button>
          <Button plain onClick={() => this.resetPassword(item.email)}>Ré-initialiser mot de passe</Button>
        </div>
      );

      let showInCalendar = item.calendar === undefined || item.calendar === 'show';
      let calendar = (
        <div>
          <ButtonGroup segmented>
            <Button pressed={showInCalendar} onClick={() => this.showUserInCalendar(item, true)}>
              Oui
            </Button>
            <Button pressed={!showInCalendar} onClick={() => this.showUserInCalendar(item, false)}>
              Non
            </Button>
          </ButtonGroup>
        </div>
      );

      const editableRolesOptions = [
        ...roleOptions,
        {label: 'Inactif', value: 'inactif'},
      ];
      let roleSelector = (
        <div>
          <Select
            options={editableRolesOptions}
            onChange={async (value) => {
              this.setUpdatingUser(item.id, true)
              await this.changeRole(item, value)
              this.setUpdatingUser(item.id, false)
            }}
            value={item.role}
          />
        </div>
      )

      let updatingUser = this.state.updatingUser[item.id]
      let updatingUserIndicator = (<div>
        {updatingUser && <Spinner size="small" />}
      </div>)

      rows.push([
        ordering,
        //item.role,
        item.name,
        item.email,
        moment(item.created).fromNow(),
        calendar,
        roleSelector,
        actions,
        updatingUserIndicator,
      ]);
    }

    return (
      <>
        <Page fullWidth title="Paramètres">
          <Layout>
            <Layout.AnnotatedSection
              title="Nouvel utilisateur"
              description="Un admin a accès à toutes les fonctions de cette application, y compris la création/suppression d'utilisateurs (admin et/ou guide). Un guide a accès au calendrier de réservations et de locations, mais ne peut créer ou modifier les événements."
            >
              <Card sectioned>
                <Card.Section>
                  <Form onSubmit={this.addResource}>
                    <FormLayout>
                      <FormLayout.Group>
                        <TextField
                          value={newUserName}
                          onChange={this.handleChange("newUserName")}
                          label="Nom"
                          type="text"
                        />
                        <TextField
                          value={newUserEmail}
                          onChange={this.handleChange("newUserEmail")}
                          label="Email"
                          type="email"
                        />
                      </FormLayout.Group>
                      <FormLayout.Group>
                        <Select
                          label="Role"
                          options={roleOptions}
                          onChange={this.handleChange("newUserRole")}
                          value={newUserRole}
                        />
                      </FormLayout.Group>
                      <Stack distribution="trailing">
                        <Button primary submit>
                          Ajouter
                        </Button>
                      </Stack>
                    </FormLayout>
                  </Form>
                </Card.Section>
              </Card>
            </Layout.AnnotatedSection>

            <Layout.Section>
              <TextContainer spacing="loose">
                <Heading>Utilisateurs existants</Heading>
                <p></p>
              </TextContainer>
              {loaded ? (
                <Card sectioned>
                  <DataTable
                    columnContentTypes={[
                      "text",
                      "text",
                      "text",
                      "text",
                      "text",
                      "text",
                    ]}
                    headings={["", "Nom", "Email", "Créé", "Afficher au calendrier", "Rôle","",""]}
                    rows={rows}
                  />
                </Card>
              ) : (
                  <Card sectioned>Loading...</Card>
                )}
            </Layout.Section>
          </Layout>
        </Page>
        <Modal
          open={confirmDeleteModal}
          onClose={() => this.setState({ confirmDeleteModal: false })}
          title="Confirmer la suppression"
          primaryAction={{
            content: "Supprimer",
            onAction: () => this.deleteResource(),
          }}
          secondaryActions={[
            {
              content: "Annuler",
              onAction: () => this.setState({ confirmDeleteModal: false }),
            },
          ]}
        >
          <Modal.Section>
            <TextContainer>
              <p>
                Voulez-vous vraiment supprimer cet utilisateur?
              </p>
              <p>ATTENTION: Si des événements sont assignés à cet utilisateur, ils n'appaîtront plus dans le calendrier. Veuillez vérifier que cet utilisateur n'a aucun événement assigné.</p>
            </TextContainer>
          </Modal.Section>
        </Modal>
      </>
    );
  }

  handleChange = (field) => {
    return (value) => {
      this.setState({ [field]: value });
    };
  };
}

export default connect(null, { setUsers, showToast })(Settings);
