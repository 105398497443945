import React from "react";
import { connect } from "react-redux";
import { Layout, Page, TextStyle, Spinner } from "@shopify/polaris";
import { database, DOMAIN_PREFIX } from "../firebase";
import moment from "moment";
import RentalsCalendarComponent from "../components/RentalsCalendarComponent";
import { parseOrderString } from "../events/event";

class RentalsCalendar extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      loaded: false,
      loadingEvents: false,
      resources: [],
      events: [],
      enabledSkus: [],
    };
  }

  componentDidMount() {
    if (this.props.storeData.rentalsProductsLoaded) {
      this.initialize();
    }
  }

  componentDidUpdate() {
    if (this.props.storeData.rentalsProductsLoaded && !this.state.loaded) {
      this.initialize();
    }
  }

  initialize = () => {
    const { rentalsProducts } = this.props.storeData;
    let enabledSkus = [];
    let resources = [];

    rentalsProducts.forEach((product, index) => {
      let productSkus = product.variants.map((variant) => variant.sku);
      enabledSkus = [...enabledSkus, ...productSkus];

      let groupId = `r${index}`;
      resources.push({
        id: groupId,
        name: product.title,
        groupOnly: true,
      });

      product.variants.forEach((variant) => {
        resources.push({
          id: variant.sku,
          name: variant.title,
          parentId: groupId,
        });
      });
    });

    this.setState({
      enabledSkus,
      resources,
      loaded: true,
    });
  };

  fetchOrders = async (start, end) => {
    const { enabledSkus } = this.state;

    let events = [];
    let skus = [];
    let promises = [];

    for (let sku of enabledSkus) {
      const snapshot = database
        .ref(`/orders/${DOMAIN_PREFIX}`)
        .child("rentals")
        .child(sku)
        .orderByKey()
        .startAt(start)
        .endAt(end)
        .once("value");
      promises.push(snapshot);
      skus.push(sku);
    }

    await Promise.all(promises).then((values) => {
      let rentalIndex = 0;

      values.forEach((snap, i) => {
        const orders = snap.val();

        if (orders) {
          for (const [key, value] of Object.entries(orders)) {
            value.forEach((orderNumber) => {
              events.push(this.createEvent(skus[i], key, orderNumber, rentalIndex));
              rentalIndex++;
            });
          }
        }
      })
    });

    this.setState({ events, loadingEvents: false });
  };

  createEvent = (sku, date, orderNumber, rentalIndex) => {
    var returnObj = {};
    returnObj.id = `rental-id-${rentalIndex}`;
    returnObj.start = moment(date).startOf("day").format("YYYY-MM-DD HH:mm:ss");
    returnObj.end = moment(date).endOf("day").format("YYYY-MM-DD HH:mm:ss");
    returnObj.resourceId = sku;
    returnObj.bgColor = "#09f";
    returnObj.movable = true;
    const parsedOrder = parseOrderString(orderNumber);
    returnObj.orderNumber = parsedOrder.orderNumber;
    returnObj.orderId = parsedOrder.orderId;
    returnObj.title = `#${parsedOrder.orderNumber}`;
    returnObj.showPopover = false;

    return returnObj;
  };

  _handleDateRangeChange = (startDate, endDate) => {
    this.setState({
      loadingEvents: true,
    });

    const start = moment(startDate, "YYYY-MM-DD")
      .startOf("day")
      .format("YYYY-MM-DD");
    const end = moment(endDate, "YYYY-MM-DD").endOf("day").format("YYYY-MM-DD");
    this.fetchOrders(start, end);
  };

  render() {
    const { rentalsProducts } = this.props.storeData;
    const { loaded, loadingEvents, events, resources } = this.state;

    let content = <TextStyle>Chargement...</TextStyle>;

    if (loaded) {
      content = (
        <RentalsCalendarComponent
          events={events}
          resources={resources}
          enabledProducts={rentalsProducts}
          handleDateRangeChange={this._handleDateRangeChange}
          {...this.props}
        />
      );
    }

    return (
      <Page
        fullWidth
        title="Locations"
        titleMetadata={loadingEvents && <Spinner size="small" color="teal" />}
      >
        <Layout>
          <Layout.Section>{content}</Layout.Section>
        </Layout>
      </Page>
    );
  }
}

const mapStateToProps = (state) => {
  const { storeData } = state;
  return { storeData };
};

export default connect(mapStateToProps)(RentalsCalendar);
