export const SET_PRODUCTS = "SET_PRODUCTS";
export const SET_ALL_PRODUCTS = "SET_ALL_PRODUCTS";
export const SET_ENABLED_PRODUCTS = "SET_ENABLED_PRODUCTS";
export const SET_RENTALS_PRODUCTS = "SET_RENTALS_PRODUCTS";
export const SHOW_TOAST = "SHOW_TOAST";
export const HIDE_TOAST = "HIDE_TOAST";
export const SET_USERS = "SET_USERS";
export const SET_USER_ROLE = "SET_USER_ROLE";
export const SET_USER_ID = "SET_USER_ID";
export const SET_DESTINATIONS = "SET_DESTINATIONS";
