import moment from "moment";
import { getEventBgColor, getEventShortenTitle } from "./utils";
import { database, DOMAIN_PREFIX } from "../firebase";

const EMPTY_EVENT = {
  id: null,
  start: null,
  end: null,
  resourceId: 'unassigned',
  location: null,
  language: null,
  manualOrder: undefined,
  handle: null,
  notes: '',
  destinations: {},
  colorName: "auto",
  orders: null,
  movable: false,
  product: null,
  variant: undefined,
  movable: true,
  dayoff: false,
}

export function buildFromFirebase(firebaseEventId, firebaseEvent, products) {
  const event = {...EMPTY_EVENT};

  let product = products.find(
    (x) => x.handle === firebaseEvent.handle
  );

  event.id = firebaseEventId;
  event.start = moment(firebaseEvent.start).format("YYYY-MM-DD HH:mm:ss");
  event.end = moment(firebaseEvent.end).format("YYYY-MM-DD HH:mm:ss");

  event.resourceId = firebaseEvent.resource;
  event.location = firebaseEvent.location;
  event.language = firebaseEvent.language;
  event.manualOrder = !!firebaseEvent.manualOrder;
  event.handle = firebaseEvent.handle;
  event.notes = firebaseEvent.notes || '';
  event.destinations = firebaseEvent.destinations ? {...firebaseEvent.destinations} : {};
  event.customer = firebaseEvent.customer;
  event.participants = firebaseEvent.participants;
  event.colorName = firebaseEvent.colorName;
  event.orders = firebaseEvent.orders;
  event.dayoff = firebaseEvent.dayoff;
  event.product = product;

  if (product && product?.variants?.length > 0) {
    event.variant = product.variants.find(item => parseInt(item.id) === parseInt(firebaseEvent.variantId));
  }

  return calculateEventFields(event);
}

export function buildNew(start, end, resourceId) {
  const event = {...EMPTY_EVENT};

  event.start = start;
  event.end = end;
  event.resourceId = resourceId;
  event.language = "fren";

  return calculateEventFields(event);
}

export function buildDayOff(start, end, resourceId) {
  const event = buildNew(start, end, resourceId);
  event.dayoff = true;
  return calculateEventFields(event);
}

export function saveToFirebase(event) {
  let dbEntry = {
    start: moment(event.start, "YYYY-MM-DD HH:mm:ss").toISOString(),
    end: moment(event.end, "YYYY-MM-DD HH:mm:ss").toISOString(),
    resource: event.resourceId,
    handle: event.product ? event.product.handle : (event.dayoff ? "non-disponible" : ""),
    dayoff: event.dayoff
  };

  if (!event.dayoff) {
    dbEntry = {
      ...dbEntry,
      location: event.location,
      language: event.language,
      lang: event.language,
      colorName: event.colorName,
      variantId: event.variant ? `${event.variant.id}` : "",
    }
  }

  const newEventRef = database.ref(`/events/${DOMAIN_PREFIX}`).push();
  event.id = newEventRef.key;

  return new Promise((resolve, reject) => {
    newEventRef.set(dbEntry, (error) => {
      if (error) {
        reject(error);
      } else {
        resolve();
      }
    });
  });
}

function getEventTitle(event) {
  if (event.dayoff) {
    return "NON DISPONIBLE";
  } else if (event.handle === 'ski-hors-piste-guide' &&
              event.customer?.firstName &&
              event.customer?.lastName) {
    return `${event.customer.firstName} ${event.customer.lastName}`;
  }
  return getEventShortenTitle(event.product?.title);
}

export function parseOrderString(orderString) {
  console.log("parsing order ", orderString);
  if (orderString.indexOf("::") != -1) {
    const parts = orderString.split("::");
    return {
      orderNumber: parts[0],
      orderId: parts[1],
    };
  }
  return {
    orderNumber: orderString,
    orderId: orderString,
  };
}

//Event was a class, but we cannot use a class with React DND, it must be a simple object.
//So we updated calculated fields manually
export function calculateEventFields(event) {
  event.title = getEventTitle(event);

  //At first we had 2 cases for order
  //1 - the order is created in shopify and sent to firebase via webhook. In that case, we store the shopify internal order id in the orders array in firebase
  //2 - an admin clicks sur button on the calendar page and enter an order manually. In that case, when enter the shopify display number (not the internal shopify id)
  //    and the event.manualOrder is set to true
  //
  //For case #1, we now want to store both the shopify internal id and display number. To avoid having to change the format
  //of all the previous orders in firebase, we now store a string formatted like this in firebase: [shopify-order-number]::[shopify-order-id]
  //We need to support older orders and new orders at the same time
  const orderString = event.orders && event.orders.length > 0 ? event.orders[0] : "";
  if (orderString !== "") {
    const parsedOrder = parseOrderString(orderString);
    event.orderNumber = parsedOrder.orderNumber;
    event.orderId = parsedOrder.orderId;
  }

  if (event.product) {
    event.handle = event.product.handle;
  }
  event.bgColor = getEventBgColor(event);
  return event;
}

export function setEventProduct(event, product, variantId) {
  event.product = product;
  if (product) {
    event.handle = product.handle;
    if (variantId) {
      event.variant = product.variants.find(item => parseInt(item.id) === parseInt(variantId));
    }
  }
  return event;
}

export function setEventOrderNumber(event, orderNumber) {
  if (event.orders && event.orders.length > 0) {
    event.orders[0] = orderNumber;
  } else {
    event.orders = [orderNumber];
  }
}


