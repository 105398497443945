import React from "react";
import Scheduler, {
  SchedulerData,
  ViewTypes,
  DATE_FORMAT,
} from "react-big-scheduler";
import "react-big-scheduler/lib/css/style.css";
import 'react-confirm-alert/src/react-confirm-alert.css';
import moment from "moment";
import { connect } from "react-redux";
import { showToast } from "../redux/actions";

import withDnDContext from "./withDnDContext";


class RentalsCalendarComponent extends React.Component {
  constructor(props) {
    super(props);

    this.schedulerData = new SchedulerData(
      new moment().format(DATE_FORMAT),
      ViewTypes.Week,
      false,
      false,
      {
        schedulerWidth: "95%",

        dayResourceTableWidth: 200,
        weekResourceTableWidth: 200,
        monthResourceTableWidth: 200,
        quarterResourceTableWidth: 200,
        yearResourceTableWidth: 200,
        customResourceTableWidth: 200,

        dayMaxEvents: 99,
        weekMaxEvents: 99,
        monthMaxEvents: 99,
        quarterMaxEvents: 99,
        yearMaxEvents: 99,
        customMaxEvents: 99,

        nonAgendaSlotMinHeight: 50,
        selectedAreaColor: "rgba(0,0,0,0.4)",
        nonWorkingTimeHeadBgColor: "#fff0f6",
        nonWorkingTimeBodyBgColor: "#fff0f6",

        startResizable: true,
        endResizable: true,
        movable: true,
        creatable: true,
        crossResourceMove: true,
        checkConflict: false,
        scrollToSpecialMomentEnabled: true,
        eventItemPopoverEnabled: true,
        calendarPopoverEnabled: true,
        recurringEventsEnabled: true,
        headerEnabled: true,
        displayWeekend: true,
        relativeMove: true,

        resourceName: "Produits",
        taskName: "Tâche",
        addMorePopoverHeaderFormat: "MMM DD, YYYY dddd",
        eventItemPopoverDateFormat: "MMM DD",
        nonAgendaDayCellHeaderFormat: "ha",
        nonAgendaOtherCellHeaderFormat: "ddd MM/DD",

        minuteStep: 30,

        views: [
          {
            viewName: "Journée",
            viewType: ViewTypes.Day,
            showAgenda: false,
            isEventPerspective: false,
          },
          {
            viewName: "Semaine",
            viewType: ViewTypes.Week,
            showAgenda: false,
            isEventPerspective: false,
          },
          {
            viewName: "Mois",
            viewType: ViewTypes.Month,
            showAgenda: false,
            isEventPerspective: false,
          },
        ],
      }
    );
    this.schedulerData.setResources(this.props.resources);
    this.schedulerData.setEvents(this.props.events);

    this.state = {
      viewModel: this.schedulerData
    };

    this.props.handleDateRangeChange(
      this.schedulerData.startDate,
      this.schedulerData.endDate
    );
  }

  componentDidUpdate(prevProps) {
    if (this.props.events !== prevProps.events) {
      this.schedulerData.setEvents(this.props.events);
      this.forceUpdate();
    }
  }

  _openModal = () => {
    this.setState({ modalOpen: true });
  };
  _closeModal = () => {
    this.setState({ modalOpen: false });
  };

  handleChange = (key) => {
    return (value) => {
      let { newEventUserInputs } = this.state;
      newEventUserInputs[key] = value;
      this.setState({ newEventUserInputs });
    };
  };

  render() {
    const {
      viewModel
    } = this.state;

    return (
      <>
        <Scheduler
          schedulerData={viewModel}
          prevClick={this.prevClick}
          nextClick={this.nextClick}
          onSelectDate={this.onSelectDate}
          onViewChange={this.onViewChange}
          eventItemClick={this.eventClicked}
          moveEvent={this.moveEvent}
          newEvent={this.newEvent}
          toggleExpandFunc={this.toggleExpandFunc}
        />
      </>
    );
  }

  prevClick = (schedulerData) => {
    schedulerData.prev();
    this.forceUpdate();

    this.props.handleDateRangeChange(
      this.schedulerData.startDate,
      this.schedulerData.endDate
    );
  };

  nextClick = (schedulerData) => {
    schedulerData.next();
    this.forceUpdate();

    this.props.handleDateRangeChange(
      this.schedulerData.startDate,
      this.schedulerData.endDate
    );
  };

  onViewChange = (schedulerData, view) => {
    schedulerData.setViewType(
      view.viewType,
      view.showAgenda,
      view.isEventPerspective
    );
    this.forceUpdate();

    this.props.handleDateRangeChange(
      this.schedulerData.startDate,
      this.schedulerData.endDate
    );
  };

  onSelectDate = (schedulerData, date) => {
    schedulerData.setDate(date);
    this.forceUpdate();

    this.props.handleDateRangeChange(
      this.schedulerData.startDate,
      this.schedulerData.endDate
    );
  };

  eventClicked = (schedulerData, event) => {
    if (event.orderId) {
      var win = window.open(
        `https://skichicchocs.com/admin/orders/${event.orderId}`,
        "_blank"
      );
      win.focus();
    }
  };

  newEvent = (schedulerData, slotId, slotName, start, end, type, item) => {
    console.log("Clicked new event");
  };

  moveEvent = (schedulerData, event, slotId, slotName, start, end) => {
    console.log("Option not available");
  };

  toggleExpandFunc = (schedulerData, slotId) => {
    schedulerData.toggleExpandStatus(slotId);
    this.setState({
      viewModel: schedulerData
    });
  }
}

const dndCalendarComponent = withDnDContext(RentalsCalendarComponent);

export default connect(
  null,
  { showToast }
)(dndCalendarComponent);
