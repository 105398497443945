import { SET_USERS, SET_USER_ROLE, SET_USER_ID } from "../actionTypes";

const initialState = {
  loaded: false,
  currentRole: null,
  users: {},
};

export default function(state = initialState, action) {
  switch (action.type) {
    case SET_USER_ROLE: {
      const { role } = action.payload;
      return {
        ...state,
        currentRole: role
      }
    }
    case SET_USERS: {
      const { data } = action.payload;
      return {
        ...state,
        loaded: true,
        users: {...data}
      }
    }
    case SET_USER_ID: {
      const { userId } = action.payload;
      return {
        ...state,
        userId
      }
    }
    default:
      return state;
  }
}
