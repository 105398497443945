import { SET_ALL_PRODUCTS, SET_PRODUCTS, SET_ENABLED_PRODUCTS, SET_RENTALS_PRODUCTS } from "../actionTypes";

const initialState = {
  loaded: false,
  enabledProductsLoaded: false,
  rentalsProductsLoaded: false,
  products: [],
  enabledProducts: [],
  rentalsProducts: [],
  allProducts: [],
};

export default function(state = initialState, action) {
  switch (action.type) {
    case SET_PRODUCTS: {
      const { data } = action.payload;
      return {
        ...state,
        loaded: true,
        products: ('data' in data && 'products' in data.data) ? data.data.products : []
      }
    }
    case SET_ENABLED_PRODUCTS: {
      const { data } = action.payload;
      return {
        ...state,
        enabledProductsLoaded: true,
        enabledProducts: ('data' in data && 'products' in data.data) ? data.data.products : []
      }
    }
    case SET_RENTALS_PRODUCTS: {
      const { data } = action.payload;
      return {
        ...state,
        rentalsProductsLoaded: true,
        rentalsProducts: ('data' in data && 'products' in data.data) ? data.data.products : []
      }
    }
    case SET_ALL_PRODUCTS: {
      const { data } = action.payload;
      return {
        ...state,
        allProducts: ('data' in data && 'products' in data.data) ? data.data.products : []
      }
    }
    default:
      return state;
  }
}
