import React from "react";
import { connect } from "react-redux";
import { Layout, Page, Card, TextStyle, Spinner, DescriptionList, Subheading } from "@shopify/polaris";
import { database, DOMAIN_PREFIX } from "../firebase";
import moment from "moment";
import { fetchFirebaseEvents, getNbCustomersForEvent } from "../events/utils";

class DailyReport extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      loaded: false,
      events: null,
    };
  }

  async loadEvents() {
    let { events, loaded } = this.state;
    if (!loaded && events === null) {
      this.setState({
        ...this.state,
        events: await this.fetchDayEvents()
      });
    }
  }

  isReduxDataLoaded(props) {
    return  props.storeData && props.storeData.enabledProductsLoaded &&
            props.users && props.users.loaded &&
            props.destinations && props.destinations.loaded;
  }

  componentDidMount() {
    if (this.isReduxDataLoaded(this.props)) {
      this.loadEvents();
    }
  }

  componentDidUpdate(prevProps) {
    let { events, loaded } = this.state;
    if (!loaded) {
      const reduxLoaded = this.isReduxDataLoaded(this.props);
      if (!this.isReduxDataLoaded(prevProps) && reduxLoaded) {
        this.loadEvents();
      } else if (reduxLoaded && events !== null) {
        this.setState({
          ...this.state,
          loaded: true,
        });
      }
    }
  }

  fetchDayEvents = async () => {
    //Since we have events spawning multiple days, we fetch 14 days before
    const startFilter = moment().startOf('day').subtract(14, 'days');
    const endFilter = moment().endOf('day');
    const { enabledProducts } = this.props.storeData;

    const events = await fetchFirebaseEvents(database, DOMAIN_PREFIX, enabledProducts, startFilter, endFilter);
    const isApplicableForDay = (day) => {
      return (event) => {
        const start = moment(event.start, "YYYY-MM-DD HH:mm:ss");
        const end = moment(event.end, "YYYY-MM-DD HH:mm:ss");
        return start.startOf('day').isSameOrBefore(day) && end.endOf('day').isAfter(day);
      }
    }
    return events.filter(isApplicableForDay(moment().startOf('day')));
  };

  getResourcesAndEventsList = () => {
    const { events } = this.state;
    let resourcesArray = [];
    for (let key in this.props.users.users) {
      if (key !== 'unassigned') {
        const user = this.props.users.users[key];
        const resourceEvents = events.filter((e) => e.resourceId === key && !e.dayoff);
        const resource = {
          id: key,
          order: user.order,
          name: user.name,
          events: resourceEvents,
        };
        resourcesArray.push(resource);
      }
    }
    resourcesArray.sort((a, b) => {
      //no events are placed last
      if (a.events.length === 0 && b.events.length !== 0) return 1;
      if (b.events.length === 0 && a.events.length !== 0) return -1;
      return a.order - b.order;
    });
    return resourcesArray;
  }

  render() {
    const { loaded } = this.state;

    let content = <TextStyle>Chargement...</TextStyle>;
    const today = moment().format('dddd D MMMM')

    const getDestination = (destinationId) => {
      const { destinations } = this.props.destinations;
      return destinations[destinationId];
    }

    if (loaded) {
      const resourcesWithEvents = this.getResourcesAndEventsList();

      const allEvents = resourcesWithEvents.map((r) => r.events).flat();
      const nbCustomers = allEvents.reduce((acc, event) => {return acc + getNbCustomersForEvent(event)}, 0)

      content = (
        <div>
          <Card sectioned>
            <p>Nombre de clients sur le terrain: {nbCustomers}</p>
          </Card>
          {resourcesWithEvents.map((resource) =>
            {
              if (resource.events.length === 0) {
                return <Card key={resource.id} title={resource.name} sectioned>
                  <p>Aucune activité</p>
                </Card>
              }
              return resource.events.map((event) =>
                <Card key={`card-${event.id}-${resource.id}`} title={resource.name} sectioned>
                    <DescriptionList key={`props-${event.id}-${resource.id}`}
                      items={(() => {
                        const arr = [{
                          term: "Activité",
                          description: event.product.title
                        },
                        {
                          term: "Début",
                          description: event.start
                        },
                        {
                          term: "Fin",
                          description: event.end
                        }];
                        //for ski-hors-piste, dont display variant since we display the number of person below
                        if (event.variant && event.handle !== 'ski-hors-piste-guide') {
                          arr.push({
                            term: "Variante",
                            description: event.variant.title,
                          })
                        }
                        if (event.destination) {
                          arr.push({
                            term: "Destination",
                            description: getDestination(event.destination)
                          })
                        };
                        if (event.notes) {
                          arr.push({
                            term: "Notes",
                            description: <div>{event.notes.split("\n").map((t, index) => <p key={index}>{t}</p>)}</div>
                          })
                        };
                        const nbCustomers = getNbCustomersForEvent(event);
                        if (nbCustomers !== 0) {
                          arr.push({
                            term: "Nombre de clients",
                            description: `${nbCustomers}`
                          })
                        }
                        if (event.participants) {
                          arr.push({
                            term: "Participants",
                            description: <div>{event.participants.map((p, index) => <p key={index}>{p.name}</p>)}</div>
                          })
                        }
                        if (event.customer) {
                          arr.push({
                            term: "Client",
                            description: `${event.customer.firstName} ${event.customer.lastName}`
                          })
                        }
                        return arr;
                      })()}
                    />
                </Card>)})}
        </div>
      );
    }

    return (
      <Page
        title="Rapport journalier"
        titleMetadata={!loaded && <Spinner size="small" color="teal" />}
      >
        <Layout>
          <Layout.Section>
            <Subheading>{today}</Subheading>
          </Layout.Section>
          <Layout.Section>{content}</Layout.Section>
        </Layout>
      </Page>
    );
  }
}

const mapStateToProps = (state) => {
  const { storeData, users, destinations } = state;
  return { storeData, users, destinations };
};

export default connect(mapStateToProps)(DailyReport);
