import React from "react";
import {
  Button,
  Card,
  Layout,
  Page,
  ResourceList,
  ResourceItem,
  Spinner,
  TextStyle,
  Thumbnail,
} from "@shopify/polaris";
import { database, DOMAIN_PREFIX } from "../firebase";
import { connect } from "react-redux";
import { showToast } from "../redux/actions";
import moment from "moment";
import Flatpickr from "react-flatpickr";
import "flatpickr/dist/themes/light.css";
import {parseOrderString} from '../events/event';

class DailyRentals extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loaded: false,
      loadingEvents: false,
      modalOpen: false,
      enabledSkus: [],
      dateSelected: moment().format("YYYY-MM-DD"),
      ordersForDate: {},
    };
  }

  componentDidMount() {
    if (this.props.storeData.rentalsProductsLoaded) {
      this.initialize();
    }
  }

  componentDidUpdate() {
    if (this.props.storeData.rentalsProductsLoaded && !this.state.loaded) {
      this.initialize();
    }
  }

  initialize = () => {
    const { rentalsProducts } = this.props.storeData;

    let enabledSkus = [];
    rentalsProducts.forEach((product) => {
      let productSkus = product.variants.map((variant) => variant.sku);
      enabledSkus = [...enabledSkus, ...productSkus];
    });

    this.setState({
      enabledSkus,
      loaded: true
    });

    this.loadOrdersForDate(this.state.dateSelected, enabledSkus);
  };

  _openModal = () => {
    this.setState({ modalOpen: true });
  };
  _closeModal = () => {
    this.setState({ modalOpen: false });
  };

  loadOrdersForDate = async (date, enabledSkus) => {
    this.setState({ loadingEvents: true });

    if (!enabledSkus) {
      const { stateEnabledSkus } = this.state;
      enabledSkus = stateEnabledSkus;
    }

    let ordersForDate = {};
    let skus = [];
    let promises = [];

    for (let sku of enabledSkus) {
      const snapshot = database
        .ref(`/orders/${DOMAIN_PREFIX}`)
        .child("rentals")
        .child(sku)
        .child(date)
        .once("value");
      promises.push(snapshot);
      skus.push(sku);
    }

    await Promise.all(promises).then((values) => {
      values.forEach((snap, i) => {
        const orders = snap.val();

        if (orders) {
          ordersForDate[skus[i]] = orders;
        }
      });
    });

    this.setState({
      ordersForDate,
      dateSelected: date,
      loadingEvents: false,
    });

    this.props.showToast("Chargement complété", 2000);
  };

  handleDateChange = () => {
    return (value) => {
      value = moment(value[0]).format("YYYY-MM-DD");
      this.loadOrdersForDate(value);
    };
  };

  handleChange = (key) => {
    return (value) => {
      let { newEventUserInputs } = this.state;
      newEventUserInputs[key] = value;
      this.setState({ newEventUserInputs });
    };
  };

  render() {
    const { rentalsProducts } = this.props.storeData;
    const { loaded, loadingEvents, dateSelected } = this.state;

    let content = <TextStyle>Chargement...</TextStyle>;

    if (loaded) {
      let productsResourcesLists = [];
      rentalsProducts.forEach((product) => {
        if (product.variants && product.variants.length > 0) {
          let productResourceList = (
            <Card.Section title={`Produit: ${product.title}`} key={product.id}>
              <ResourceList
                resourceName={{ singular: "variant", plural: "variants" }}
                items={product.variants}
                renderItem={this.renderItem}
                showHeader={false}
              />
            </Card.Section>
          );
          productsResourcesLists.push(productResourceList);
        }
      });

      content = (
        <Card>
          <Card.Section title="Voir les locations du">
            <Flatpickr
              value={dateSelected}
              options={{
                enableTime: false,
                dateFormat: "Y-m-d",
                static: true,
              }}
              onChange={this.handleDateChange()}
              required
            />
          </Card.Section>
          {productsResourcesLists}
        </Card>
      );
    }

    return (
      <>
        <Page
          title="Locations de la journée"
          titleMetadata={loadingEvents && <Spinner size="small" color="teal" />}
        >
          <Layout>
            <Layout.Section>{content}</Layout.Section>
          </Layout>
        </Page>
      </>
    );
  }

  renderItem = (item) => {
    const { id, title, sku, images } = item;
    const media = <Thumbnail source={(images && images.length > 0) ? images[0].src : ""} />;

    let skuText = sku ? (
      sku
    ) : (
      <TextStyle variation="negative">
        Vous devez entrer un SKU à partir du produit pour cette variante
      </TextStyle>
    );
    let orders = this.state.ordersForDate[sku];
    let ordersLinks = [];
    if (orders) {
      orders.forEach((orderString) => {
        const parsedOrder = parseOrderString(orderString);
        let url = `https://skichicchocs.com/admin/orders/${parsedOrder.orderId}`;
        let button = (
          <Button primary key={sku + "-order-" + parsedOrder.orderNumber} url={url} external>
            {parsedOrder.orderNumber}
          </Button>
        );
        ordersLinks.push(button);
      });
    }

    return (
      <ResourceItem id={id} media={media}>
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <div>
            <h3>
              <TextStyle variation="strong">
                {title} ({skuText})
              </TextStyle>
            </h3>
          </div>
          <div>
            {ordersLinks.length > 0 ? (
              ordersLinks
            ) : (
              <div>Aucune réservation</div>
            )}
          </div>
        </div>
      </ResourceItem>
    );
  };
}

const mapStateToProps = (state) => {
  const { storeData } = state;
  return { storeData };
};

export default connect(mapStateToProps, { showToast })(DailyRentals);
