import React from "react";
import Scheduler, {
  SchedulerData,
  ViewTypes,
  DATE_FORMAT,
} from "react-big-scheduler";
import "react-big-scheduler/lib/css/style.css";
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';
import { database, DOMAIN_PREFIX, functions } from "../firebase";
import moment from "moment";
import { connect } from "react-redux";
import { showToast } from "../redux/actions";
import {
  buildNew,
  buildDayOff } from "../events/event";
import {getApplicableEventsForDate} from "../events/utils";
import {CalendarEventModalComponent} from './CalendarEventModalComponent';

import withDnDContext from "./withDnDContext";

const newEventStartHour = 9;
const newEventStartMin = 0;
const newEventEndHour = 16;
const newEventEndMin = 30;
const newEventProps = {
  handle: "",
  variantId: "",
  location: "ste-anne-des-monts",
  language: "fren",
  nbDuplicateEvents: 1,
  inventory: 1,
  notes: "",
  destinations: [],
  manualOrder: false,
  orderNumber: "",
  colorName: 'auto',
};

function dateWithTime(dt, hour, min) {
  const format = "YYYY-MM-DD HH:mm:ss";
  return moment(dt,format).hour(hour).minute(min).second(0).format(format);
}


class CalendarComponent extends React.Component {
  constructor(props) {
    super(props);

    this.schedulerData = new SchedulerData(
      new moment().format(DATE_FORMAT),
      ViewTypes.Week,
      false,
      false,
      {
        schedulerWidth: "95%",

        dayResourceTableWidth: 200,
        weekResourceTableWidth: 200,
        monthResourceTableWidth: 200,
        quarterResourceTableWidth: 200,
        yearResourceTableWidth: 200,
        customResourceTableWidth: 200,

        dayMaxEvents: 99,
        weekMaxEvents: 99,
        monthMaxEvents: 99,
        quarterMaxEvents: 99,
        yearMaxEvents: 99,
        customMaxEvents: 99,

        nonAgendaSlotMinHeight: 50,
        selectedAreaColor: "rgba(0,0,0,0.4)",
        nonWorkingTimeHeadBgColor: "#fff0f6",
        nonWorkingTimeBodyBgColor: "#fff0f6",

        startResizable: true,
        endResizable: true,
        movable: this.props.users.currentRole === 'admin' ? true : false,
        creatable: true,
        crossResourceMove: true,
        checkConflict: false,
        scrollToSpecialMomentEnabled: true,
        eventItemPopoverEnabled: true,
        calendarPopoverEnabled: true,
        recurringEventsEnabled: true,
        headerEnabled: true,
        displayWeekend: true,
        relativeMove: true,

        resourceName: "Employé",
        taskName: "Tâche",
        addMorePopoverHeaderFormat: "MMM DD, YYYY dddd",
        eventItemPopoverDateFormat: "MMM DD",
        nonAgendaDayCellHeaderFormat: "ha",
        nonAgendaOtherCellHeaderFormat: "ddd MM/DD",

        minuteStep: 30,

        views: [
          {
            viewName: "Journée",
            viewType: ViewTypes.Day,
            showAgenda: false,
            isEventPerspective: false,
          },
          {
            viewName: "Semaine",
            viewType: ViewTypes.Week,
            showAgenda: false,
            isEventPerspective: false,
          },
          {
            viewName: "Mois",
            viewType: ViewTypes.Month,
            showAgenda: false,
            isEventPerspective: false,
          },
        ],
      }
    );
    this.schedulerData.setResources(this.props.resources);
    this.schedulerData.setEvents(this.props.events);

    this.state = {
      modalOpen: false,
      modalIsNewEvent: true,
      viewModel: this.schedulerData,
      newEvent: null,
      editEvent: null,
    };

    this.props.handleDateRangeChange(
      this.schedulerData.startDate,
      this.schedulerData.endDate
    );
  }

  componentDidUpdate(prevProps) {
    if (this.props.events !== prevProps.events) {
      this.schedulerData.setEvents(this.props.events);
      this.forceUpdate();
    }
  }

  closeModal = () => {
    this.setState({ modalOpen: false });
    this.forceUpdate();
  };

  displayGuidesAvailabilityCellHeader = (schedulerData, item, formattedDateItems, style) => {
    if (schedulerData.viewType === ViewTypes.Week || schedulerData.viewType === ViewTypes.Month) {
      const applicableEvents = getApplicableEventsForDate(item.time, schedulerData.events);
      const nbFreeResources = this.props.resources.map((r) => r.id).filter((r) => {
        if (r !== 'unassigned') {
          return applicableEvents.filter((e) => e.resourceId === r).length === 0;
        }
        return false;
      }).length;
      const freeIndicator = nbFreeResources > 0 ? ` <b>[${nbFreeResources}&nbsp;libre]</b>` : " [0&nbsp;libre]"
      formattedDateItems[0] = formattedDateItems[0].replace(/[0-9]/g, '<b>$&</b>') + freeIndicator;
    }

    return (
        <th key={item.time} className={`header3-text`} style={style}>
            {
                formattedDateItems.map((formattedItem, index) => (
                    <div key={index}
                        dangerouslySetInnerHTML={{__html: formattedItem}}/>
                ))
            }
        </th>
    );
  }

  getPopoverSubtitle = (_, event) => {
    let title = event?.variant?.title || "";
    if (event) {
      //When we have a customer, the title is the name of the customer, so we write the product and variant as subtitle
      if (event.handle === 'ski-hors-piste-guide' && event.customer) {
        title = "Ski hors piste guidé" + (title ? ` - ${title}` : title);
      }
    }
    return title;
  }

  render() {
    const userRole = this.props.users.currentRole;
    const {
      modalOpen,
      modalIsNewEvent,
      viewModel,
      newEvent,
      editEvent,
    } = this.state;

    return (
      <>
        <Scheduler
          schedulerData={viewModel}
          prevClick={this.prevClick}
          nextClick={this.nextClick}
          onSelectDate={this.onSelectDate}
          onViewChange={this.onViewChange}
          eventItemClick={this.eventClicked}
          moveEvent={userRole === 'admin' ? this.moveEvent : null}
          newEvent={this.newEvent}
          viewEventText={"No. Commande"}
          viewEventClick={this.openShopifyOrder}
          subtitleGetter={this.getPopoverSubtitle}
          nonAgendaCellHeaderTemplateResolver={this.displayGuidesAvailabilityCellHeader}
        />
        { modalOpen && <CalendarEventModalComponent
                          role={userRole}
                          modalIsNewEvent={modalIsNewEvent}
                          onClose={this.closeModal}
                          event={modalIsNewEvent ? newEvent : editEvent}
                          enabledProducts={this.props.enabledProducts}
                          destinations={this.props.destinations}
                          showToast={this.props.showToast}
                          onUpdateEventStart={this.onUpdateEventStart}
                          onUpdateEventEnd={this.onUpdateEventEnd}
                          onNewEventSaved={this.onNewEventSaved}
                          onRequestEventDeletion={this.confirmDeleteEvent}
                       />}
      </>
    );
  }

  onNewEventSaved = (newEvent) => {
    this.schedulerData.addEvent(newEvent);
    this.forceUpdate();
  }

  onUpdateEventStart = (event, start) => {
    this.schedulerData.updateEventStart(event, start);
  }

  onUpdateEventEnd = (event, end) => {
    this.schedulerData.updateEventEnd(event, end);
  }

  prevClick = (schedulerData) => {
    schedulerData.prev();
    this.forceUpdate();

    this.props.handleDateRangeChange(
      this.schedulerData.startDate,
      this.schedulerData.endDate
    );
  };

  nextClick = (schedulerData) => {
    schedulerData.next();
    this.forceUpdate();

    this.props.handleDateRangeChange(
      this.schedulerData.startDate,
      this.schedulerData.endDate
    );
  };

  onViewChange = (schedulerData, view) => {
    schedulerData.setViewType(
      view.viewType,
      view.showAgenda,
      view.isEventPerspective
    );
    this.forceUpdate();

    this.props.handleDateRangeChange(
      this.schedulerData.startDate,
      this.schedulerData.endDate
    );
  };

  onSelectDate = (schedulerData, date) => {
    schedulerData.setDate(date);
    this.forceUpdate();

    this.props.handleDateRangeChange(
      this.schedulerData.startDate,
      this.schedulerData.endDate
    );
  };

  newEvent = async (schedulerData, slotId, slotName, start, end, type, item) => {
    const userRole = this.props.users.currentRole;
    if (userRole === "admin") {
      const startWithTime = dateWithTime(start, newEventStartHour, newEventStartMin);
      const endWithTime = dateWithTime(end, newEventEndHour, newEventEndMin);
      let newEvent = {
        ...buildNew(startWithTime, endWithTime, slotId),
        ...newEventProps
      };

      this.setState({
        newEvent: newEvent,
        modalIsNewEvent: true,
        modalOpen: true,
      });
    } else {
      try {
        if  (slotId === this.props.users.userId) {
          this.createDayOff(start, end);
        }
      } catch(e) {
        console.log(e);
      }
    }
  };

  confirmDeleteEvent = (id) => {
    this.closeModal();
    confirmAlert({
      title: "Supprimer l'événement",
      message: `Voulez-vous vraiment supprimer cet événement?`,
      buttons: [
        {
          label: "Confirmer",
          onClick: () => {
            this.deleteEvent(id);
          }
        },
        {
          label: "Annuler",
        },
      ],
    });
  }

  deleteEvent = (id) => {
    if (id && id !== "") {
      database
        .ref(`/events/${DOMAIN_PREFIX}`)
        .child(id)
        .remove((error) => {
          if (error) {
            this.props.showToast(
              "Une erreur est survenue lors de l'enregistrement",
              5000
            );
            console.log(error);
          } else {
            this.props.showToast("Scéance supprimée avec succès!", 2000);
            this.schedulerData.removeEventById(id);
            this.forceUpdate();
          }
        });
    }
  };

  createDayOff = async (start, end) => {
    const event = buildDayOff(start, end, this.props.users.userId);

    //optimistic UI, we create the element visually and save it lateer
    event.id = "-non-disponible-" + Math.floor(Math.random() * 100000);
    this.schedulerData.addEvent(event);
    this.forceUpdate();

    var createDayOffFn = functions.httpsCallable("createDayOff");
    try {
      const createdEvent = await createDayOffFn({
        shopId: DOMAIN_PREFIX,
        start,
        end,
        resource: this.props.users.userId
      });

      //just update the ID for future reference
      event.id = createdEvent.data.id;

    }catch(e) {
      console.log(e);
      this.props.showToast("Erreur lors de l'ajout d'une non disponibilité", 7000);
      this.schedulerData.removeEventById(event.id);
      this.forceUpdate();
    }
  }

  removeDayOff = async (event) => {
    //optimistic UI, remove first and
    this.schedulerData.removeEventById(event.id);
    this.forceUpdate();

    var removeDayOffFn = functions.httpsCallable("removeDayOff");
    try {
      await removeDayOffFn({
        shopId: DOMAIN_PREFIX,
        id: event.id
      });
      this.schedulerData.removeEventById(event.id);
      this.forceUpdate();
    }catch(e) {
      console.log(e);
      //since we were not able to remove the event in firebase, we re-add it to the UI
      //and display an error to the user
      this.schedulerData.addEvent(event);
      this.forceUpdate();
      this.props.showToast("Erreur lors de la suppression d'une non disponibilité", 7000);
    }
  }

  eventClicked = (schedulerData, event) => {
    const userRole = this.props.users.currentRole;
    if (userRole === "admin" || !event.dayoff) {
      this.setState({
        modalIsNewEvent: false,
        modalOpen: true,
        editEvent: event,
      });
    } else if (event.resourceId === this.props.users.userId && event.dayoff) {
      this.removeDayOff(event);
    }
  };

  moveEvent = (schedulerData, event, slotId, slotName, start, end) => {
    confirmAlert({
      title: "Modifier l'assignation",
      message: `Voulez-vous vraiment assigner ${event.title} à ${slotName}?`,
      buttons: [
        {
          label: "Confirmer",
          onClick: () => {
            schedulerData.moveEvent(event, slotId, slotName, event.start, event.end);

            database
              .ref(`/events/${DOMAIN_PREFIX}`)
              .child(event.id)
              .child("resource")
              .set(slotId, (error) => {
                if (error) {
                  this.props.showToast("Une erreur est survenue lors de l'enregistrement", 5000);
                } else {
                  this.props.showToast("Enregistré!", 2000);
                }
              });

            this.forceUpdate();
          },
        },
        {
          label: "Annuler",
        },
      ],
    });
  };

  openShopifyOrder = (schedulerData, event) => {
    if (event.orderId) {
      var win = window.open(
        `https://skichicchocs.com/admin/orders/${event.orderId}`,
        "_blank"
      );
      win.focus();
    }
  };
}

const dndCalendarComponent = withDnDContext(CalendarComponent);

const mapStateToProps = state => {
  const { users, destinations } = state;
  return { users, destinations };
};

export default connect(
  mapStateToProps,
  { showToast }
)(dndCalendarComponent);
