import React, { useEffect, useState, useContext, useCallback } from "react";
import { Frame, Toast, TopBar } from "@shopify/polaris";
import { AuthContext } from "../App";
import { auth } from "../firebase";
import { connect } from "react-redux";
import { hideToast, setUserId, setUserRole } from "../redux/actions";


function CustomFrame({children, toast, hideToast, users, setUserRole, setUserId}) {
  const Auth = useContext(AuthContext);
  const [isUserMenuOpen, setIsUserMenuOpen] = useState(false);
  const [user, setUser] = useState(null);
  const [userName, setUserName] = useState(null);

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged((user) => {
      if (user) {
        setUser(user);
      }
    });
    return () => unsubscribe();
  }, []);

  useEffect(() => {
    if (user && users.loaded && userName === null) {
      if (user.uid in users.users) {
        setUserName(users.users[user.uid].name);
      }
    }
  }, [user, users.loaded, users.users, userName]);



  const toggleIsUserMenuOpen = useCallback(
    () => setIsUserMenuOpen((isUserMenuOpen) => !isUserMenuOpen),
    []
  );

  const logout = () => {
    auth.signOut().then(function() {
      Auth.setLoggedIn(false);
      setUserRole(null);
      setUserId(null);
    }).catch(function(error) {
      alert("Error logging out.");
      console.log(error);
    });
  }

  const toastMarkup = toast.visible ? (
    <Toast content={toast.message} onDismiss={hideToast} duration={toast.duration} />
  ) : null;

  const userMenuMarkup = (
    <TopBar.UserMenu
      actions={[
        {
          items: [{ content: "Déconnexion", onAction: logout }],
        },
      ]}
      name={userName ? userName : "--"}
      detail={users.currentRole ? users.currentRole : "Null"}
      open={isUserMenuOpen}
      onToggle={toggleIsUserMenuOpen}
    />
  );

  const topBarMarkup = <TopBar userMenu={userMenuMarkup} />;

  return (
    <Frame topBar={topBarMarkup}>
      {children}
      {toastMarkup}
    </Frame>
  );
}

const mapStateToProps = state => {
  const { toast, users } = state;
  return { toast, users };
};

export default connect(
  mapStateToProps,
  { hideToast, setUserRole, setUserId }
)(CustomFrame);
