import React from "react";
import { connect } from "react-redux";
import { fetchAllProducts, showToast } from "../redux/actions";
import {
  Card,
  Checkbox,
  Frame,
  Layout,
  Page,
  ResourceList,
  ResourceItem,
  TextStyle,
  Thumbnail,
} from "@shopify/polaris";
import { database, DOMAIN_PREFIX } from "../firebase";


class SelectProducts extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      allProducts: props.storeData.allProducts,
      bookingsProducts: [],
      rentalsProducts: [],
    };
  }

  componentDidMount = async () => {
    if (this.props.storeData.allProducts.length === 0) {
      this.props.fetchAllProducts();
    }

    database.ref(`/shops/${DOMAIN_PREFIX}`).child('bookingsProducts').on('value', snapshot => this.setCurrentProducts(snapshot, 'bookingsProducts'));
    database.ref(`/shops/${DOMAIN_PREFIX}`).child('rentalsProducts').on('value', snapshot => this.setCurrentProducts(snapshot, 'rentalsProducts'));
  }

  componentDidUpdate(prevProps) {
    if (this.props.storeData.allProducts !== prevProps.storeData.allProducts) {
      this.setState({
        allProducts: this.props.storeData.allProducts
      })
    }
  }

  componentWillUnmount() {
    database.ref(`/shops/${DOMAIN_PREFIX}`).child('bookingsProducts').off();
    database.ref(`/shops/${DOMAIN_PREFIX}`).child('rentalsProducts').off();
  }

  setCurrentProducts = (snapshot, type) => {
    let productsArray = snapshot.val();
    if (!productsArray) {
      productsArray = [];
    }
    this.setState({ [type]: productsArray });
  }

  render() {
    let { allProducts } = this.state;

    return (
      <Frame>
      <Page
        title="Produits"
        subtitle="Sélectionnez les produits pouvant accepter les réservations"
      >
        <Layout>
          <Layout.Section>
            <Card>
              <ResourceList
                resourceName={{singular: 'product', plural: 'products'}}
                items={allProducts}
                renderItem={this.renderItem}
                loading={allProducts.length === 0}
                showHeader={false}
              />
            </Card>
          </Layout.Section>
        </Layout>
      </Page>
      </Frame>
    );
  }

  handleCheckbox = async (val, id, type) => {
    let currentArray = this.state[type];
    if (val) {
      currentArray.push(id);
    } else {
      var index = currentArray.indexOf(id);
      if (index > -1) {
        currentArray.splice(index, 1);
      }
    }

    this.setState({
      [type]: currentArray,
    });

    database.ref(`/shops/${DOMAIN_PREFIX}`).child(type).set(currentArray, error => {
      if (error) {
        this.props.showToast("Il y a eu une erreur. Rafraîchir la page", 5000);
      } else {
        this.props.showToast("Enregistré!", 2000);
      }
    });
  }

  renderItem = (item) => {
    const {bookingsProducts, rentalsProducts} = this.state;
    const {id, title, images} = item;
    const media = <Thumbnail
      source={images.length > 0 ? images[0].src : ''}
    />


    let isInBookingsProducts = bookingsProducts ? bookingsProducts.includes(id) : false;
    let isInRentalsProducts = rentalsProducts ? rentalsProducts.includes(id) : false;

    return (
      <ResourceItem
        id={id}
        media={media}
      >
        <div style={{display: 'flex', justifyContent: 'space-between'}}>
          <h3>
            <TextStyle variation="strong">{title}</TextStyle>
          </h3>
          <div>
            <Checkbox
              label="Réservations"
              checked={isInBookingsProducts}
              onChange={val => this.handleCheckbox(val, id, 'bookingsProducts')}
            /><br></br>
            <Checkbox
              label="Locations"
              checked={isInRentalsProducts}
              onChange={val => this.handleCheckbox(val, id, 'rentalsProducts')}
            />
          </div>
        </div>
      </ResourceItem>
    );
  }
}

const mapStateToProps = state => {
  const { storeData } = state;
  return { storeData };
};

export default connect(mapStateToProps, { fetchAllProducts, showToast })(SelectProducts)