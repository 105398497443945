import React, { useState, useEffect } from "react";
import "./App.css";
import { Switch, BrowserRouter as Router, Route } from "react-router-dom";
import routes from "./routes.js";
import protectedRoutes from "./protectedRoutes";
import { auth } from "./firebase";
import ProtectedRouteHoc from "./ProtectedRouteHoc";
import { connect } from "react-redux";
import {
  fetchUsers,
  fetchEnabledProducts,
  fetchRentalsProducts,
  setUserRole,
  setUserId,
  fetchDestinations } from "./redux/actions";

export const AuthContext = React.createContext(null);

function App({ fetchUsers, fetchDestinations, fetchEnabledProducts, fetchRentalsProducts, setUserRole, setUserId }) {
  const [isLoading, setIsLoading] = useState(true);
  const [isLoggedIn, setLoggedIn] = useState(false);
  const [currentUserRole, setCurrentUserRole] = useState(null);

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged(async (user) => {
      if (user) {
        fetchUsers();

        const idTokenResult = await user.getIdTokenResult();
        if (idTokenResult && idTokenResult.claims) {
          setUserRole(idTokenResult.claims.role);
          setUserId(idTokenResult.claims.user_id);
          setCurrentUserRole(idTokenResult.claims.role);

          if (idTokenResult.claims.role !== 'inactif') {
            fetchDestinations();
            fetchEnabledProducts();
            fetchRentalsProducts();
          }
        } else {
          setUserRole(null);
        }

        setLoggedIn(true);
      } else {
        console.log("No user");
      }
      setIsLoading(false);
    });
    return () => unsubscribe();
  }, [fetchUsers, fetchEnabledProducts, fetchRentalsProducts, setUserRole, setUserId, fetchDestinations]);

  return (
    <AuthContext.Provider value={{ isLoggedIn, setLoggedIn }}>
      <div className="App">
        <Router>
          {isLoading ? (<div>Loading...</div>) : (
            <Switch>
              {protectedRoutes.map(route => (
                <ProtectedRouteHoc
                  key={route.path}
                  isLoggedIn={isLoggedIn}
                  userRole={currentUserRole}
                  allowedRoles={route.allowedRoles}
                  path={route.path}
                  component={route.main}
                  exact={route.exact}
                  public={route.public}
                />
              ))}
              {routes.map(route => (
                <Route
                  key={route.path}
                  path={route.path}
                  exact={route.exact}
                  component={route.main}
                />
              ))}
            </Switch>
          )}
        </Router>
      </div>
    </AuthContext.Provider>
  );
}

export default connect(
  null,
  { fetchUsers, fetchDestinations, fetchEnabledProducts, fetchRentalsProducts, setUserRole, setUserId }
)(App);
