import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import { Provider } from 'react-redux'
import App from "./App";
import store from "./redux/store";

import "@shopify/polaris/styles.css";
import enTranslations from "@shopify/polaris/locales/en.json";
import { AppProvider } from "@shopify/polaris";
import moment from "moment";
import "moment/locale/fr-ca";
moment.locale("fr-ca");


const theme = {
  colors: {
    topBar: {
      background: 'rgb(28, 34, 96)',
    },
  },
  logo: {
    width: 120,
    topBarSource:
      'https://cdn.shopify.com/s/files/1/0106/1263/7796/files/scc_logo_white_small_f6304b97-5e3f-4168-a38d-7c229c788d06.png',
    url: '/',
    accessibilityLabel: 'Ski Chic Chocs',
  },
};

ReactDOM.render(
  <Provider store={store}>
    <AppProvider
      i18n={enTranslations}
      theme={theme}
    >
      <App />
    </AppProvider>
  </Provider>,
  document.getElementById("root")
);
