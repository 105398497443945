import React from "react";
import { connect } from "react-redux";
import { Layout, Page, TextStyle, Spinner, Button, Icon } from "@shopify/polaris";
import { database, DOMAIN_PREFIX, functions } from "../firebase";
import moment from "moment";
import CalendarComponent from "../components/CalendarComponent";
import { fetchFirebaseEvents } from "../events/utils";
import { CircleDownMajorMonotone } from '@shopify/polaris-icons';
import { showToast } from "../redux/actions";

class Calendar extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      loaded: false,
      loadingEvents: false,
      resources: [],
      resourcesLoaded: false,
      events: [],
      downloadingEvents: false,
    };
  }

  componentDidMount() {
    if (this.props.users.loaded) {
      this.setResourcesArray();
    }

    if (this.props.storeData.enabledProductsLoaded) {
      this.setState({
        loaded: true,
      });
    }
  }

  componentDidUpdate(prevProps) {
    if (this.props.users.loaded && !this.state.resourcesLoaded) {
      this.setResourcesArray();
    }

    if (this.props.storeData.enabledProductsLoaded && !this.state.loaded) {
      this.setState({
        loaded: true,
      });
    }
  }

  setResourcesArray = () => {
    let resourcesArray = [];
    for (let key in this.props.users.users) {
      let user = this.props.users.users[key];
      user.id = key;
      if (!user.calendar || user.calendar === 'show') {
        resourcesArray.push(user);
      }
    }
    resourcesArray.sort((a, b) => {
      return a.order - b.order;
    });

    this.setState({
      resources: resourcesArray,
      resourcesLoaded: true
    });
  };

  fetchEvents = async (start, end) => {
    //We always fetch 3 months of events, because we have events spawning multiple
    //days and we want to be sure to display then. (for example, if we have an event
    //from jan. 27 to feb 3, and we are displaying the whole Feb month, we wont have
    //our january event if we filter using Feb 1  to Feb 28).
    const startFilter = moment(start, 'YYYY-MM-DD').startOf('month').subtract(1, 'month');
    const endFilter = moment(start, 'YYYY-MM-DD').endOf('month').add(1, 'month');
    const { enabledProducts } = this.props.storeData;

    const events = await fetchFirebaseEvents(database, DOMAIN_PREFIX, enabledProducts, startFilter, endFilter);
    this.setState({ events, loadingEvents: false });
  };

  getVariant = (product, variantId) => {
    let variant = {};
    if (product?.variants) {
      variant = product.variants.find(item => parseInt(item.id) === parseInt(variantId));
    }
    return variant;
  }

  _handleDateRangeChange = (startDate, endDate) => {
    console.log(startDate, endDate);
    this.setState({
      loadingEvents: true,
      startDate,
      endDate,
    });

    const start = moment(startDate, "YYYY-MM-DD").startOf("day").toISOString();
    const end = moment(endDate, "YYYY-MM-DD").endOf("day").toISOString();
    this.fetchEvents(start, end);
  };

  downloadEventsExcelReport = async (setLoading) => {
    this.setState({...this.state, downloadingEvents: true});
    const { startDate, endDate } = this.state;

    var exportFn = functions.httpsCallable("exportEvents");
    try {
      const result = await exportFn({
        shopId: DOMAIN_PREFIX,
        fromDate: startDate,
        toDate: endDate
      });

      var tempLink = document.createElement('a');
      tempLink.href = result.data[0];
      tempLink.setAttribute('download', 'export.xslx');
      tempLink.click();

    }catch(e) {
      console.error(e);
      this.props.showToast("Erreur lors du téléchargement des événements", 7000);
    }

    this.setState({...this.state, downloadingEvents: false});
  }

  render() {
    const { downloadingEvents } = this.state;
    const { enabledProducts } = this.props.storeData;
    const { loaded, loadingEvents, events, resources } = this.state;
    const { currentRole } = this.props.users;

    let downloadReportButton = currentRole === 'admin' &&
      <Button plain onClick={this.downloadEventsExcelReport}>
        <Icon source={CircleDownMajorMonotone} />
      </Button>;
    let titleMeta = loadingEvents || downloadingEvents ? <Spinner size="small" color="teal" /> : downloadReportButton;
    let content = <TextStyle>Chargement...</TextStyle>;


    if (loaded) {
      content = (
        <CalendarComponent
          events={events}
          resources={resources}
          enabledProducts={enabledProducts}
          handleDateRangeChange={this._handleDateRangeChange}
          {...this.props}
        />
      );
    }

    return (
      <Page
        fullWidth
        title="Réservations"
        titleMetadata={titleMeta}
      >
        <Layout>
          <Layout.Section>
            {content}
          </Layout.Section>
        </Layout>
      </Page>
    );
  }
}

const mapStateToProps = (state) => {
  const { storeData, users } = state;
  return { storeData, users };
};

export default connect(mapStateToProps, { showToast })(Calendar);
