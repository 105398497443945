import React from "react";


class Inactive extends React.Component {
  render() {
    console.log('render inactif', this.props)
    const {userRole} = this.props
    return (<div>
      {userRole == 'inactif' && <span>L'accès ne vous est pas permis, ou votre compte est inactif. Contactez Ski Chic-Chocs.</span>}
    </div>)
  }

}

export default Inactive;
