import React from 'react';
import { Route, Redirect, withRouter } from 'react-router-dom';
import { bool, any, object } from 'prop-types';
import CustomFrame from "./components/CustomFrame";
import Menu from "./components/Menu";

const ProtectedRouteHoc = ({ component: Component, isLoggedIn, userRole, allowedRoles, ...rest }) => {
  if (allowedRoles && allowedRoles.length > 0) {
    if (allowedRoles.indexOf(userRole) === -1) {
      return <Redirect to={{ pathname: '/inactif' }} />;
    }
  }
  if (isLoggedIn || rest.public) {
		return (
			<Route
				{...rest}
				render={props => {
					return <CustomFrame>
						<Menu path={props.location.pathname} userRole={userRole}/>
						<Component {...props} userRole={userRole}></Component>
					</CustomFrame>;
				}}
			/>
		);
	}
	return <Redirect to={{ pathname: '/connexion' }} />;
};

ProtectedRouteHoc.propTypes = {
	component: any,
	isLoggedIn: bool,
	userRole: any,
	onlyAdmin: bool,
	rest: object,
	props: object,
};

export default withRouter(ProtectedRouteHoc);
