import React from "react";
import Calendar from "./views/Calendar";
import RentalsCalendar from "./views/RentalsCalendar";
import DailyRentals from "./views/DailyRentals";
import RentalsDates from "./views/RentalsDates";
import SelectProducts from "./views/SelectProducts";
import Settings from "./views/Settings";
import DailyReport from "./views/DailyReport";
import Inactive from "./views/Inactive";

const protectedRoutes = [
  { name: "Calendrier", path: "/", exact: true, allowedRoles: ["guide", "admin"], main: () => <Calendar /> },
  { name: "Calendrier des locations", path: "/calendrier-locations", exact: true, allowedRoles: ["guide", "admin"], main: () => <RentalsCalendar /> },
  { name: "Rapport journalier", path: "/rapport-jour", exact: true, allowedRoles: ["guide", "admin"], main: () => <DailyReport /> },
  { name: "Locations de la journée", path: "/locations-jour", exact: true, allowedRoles: ["guide", "admin"], main: () => <DailyRentals /> },
  { name: "Dates des locations", path: "/dates-locations", exact: true, allowedRoles: ["admin"], main: () => <RentalsDates /> },
  { name: "Activer produits", path: "/activer-produits", exact: true, allowedRoles: ["admin"], main: () => <SelectProducts /> },
  { name: "Paramètres", path: "/parametres", exact: true, allowedRoles: ["admin"], main: () => <Settings /> },
  { name: "Inactif", path: "/inactif", exact: true, main: ({userRole}) => <Inactive userRole={userRole}/> },
];

export default protectedRoutes;
