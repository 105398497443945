import React from "react";
import {
  Button,
  DescriptionList,
  Link,
  Modal,
  Form,
  FormLayout,
  Heading,
  Select,
  TextField,
  TextStyle,
} from "@shopify/polaris";
import { database, DOMAIN_PREFIX, functions } from "../firebase";
import moment from "moment";
import {
  saveToFirebase,
  calculateEventFields,
  setEventProduct,
  setEventOrderNumber } from "../events/event";
import _isEqual from 'lodash/isEqual';

const availableColors = [
  {key:10, label:"Auto", value: 'auto'},
  {key:20, label:"Jaune", value:"yellow"},
  {key:30, label:"Orange", value:"orange"},
  {key:40, label:"Gris", value:"gray"},
  {key:50, label:"Turquoise", value:"cyan"},
  {key:60, label:"Brun", value:"brown"},
  {key:70, label:"Bleu (défaut)", value:"blue"},
  {key:80, label:"Rouge", value:"red"},
  {key:90, label:"Vert", value:"green"},
  {key:100, label:"Mauve", value:"purple"},
];

function eventToUserInputs(event) {
  return {
    location: event.location,
    language: event.language,
    handle: event.product?.handle,
    variantId: event.variant ? `${event.variant.id}` : "",
    startDate: moment(event.start,"YYYY-MM-DD HH:mm:ss").format("YYYY-MM-DD"),
    startTime: moment(event.start,"YYYY-MM-DD HH:mm:ss").format("HH:mm"),
    endDate: moment(event.end,"YYYY-MM-DD HH:mm:ss").format("YYYY-MM-DD"),
    endTime: moment(event.end,"YYYY-MM-DD HH:mm:ss").format("HH:mm"),
    notes: event.notes,
    destinations: {...event.destinations},
    manualOrder: event.manualOrder,
    orderNumber: event.orderNumber,
    colorName: event.colorName,
    errors: {}
  }
}

export class CalendarEventModalComponent extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      open: true,
      eventUserInputs: eventToUserInputs(this.props.event),
    };
  }

  _closeModal = () => {
    this.setState({
      ...this.state,
      open: false
    })
    if (this.props.onClose) {
      this.props.onClose();
    }
  };

  handleChange = (key, index) => {
    return (value) => {
      let { eventUserInputs } = this.state;
      if (index) {
        if (!eventUserInputs[key]) {
          eventUserInputs[key] = [];
        }
        eventUserInputs[key][index] = value;
      } else {
        eventUserInputs[key] = value;
      }
      this.setState({ eventUserInputs });
    };
  };

  toggleManualOrder = () => {
    let { eventUserInputs } = this.state;
    this.setState({
      eventUserInputs: {
        ...eventUserInputs,
        manualOrder: !eventUserInputs.manualOrder
      }
    });
  };

  validateEvent = (eventUserInputs) => {
    const errors = {};
    const {startDate, endDate, startTime, endTime, handle} = eventUserInputs;

    let start = moment(`${startDate} ${startTime}:00`);
    let end = moment(`${endDate} ${endTime}:00`);
    if (end.isBefore(start)) {
      errors.endDateBeforeStart = true;
    }

    let product = this.props.enabledProducts.find(
      (product) => product.handle === handle
    );
    if (!product) {
      errors.missingProduct = true;
    }

    return errors;
  }

  getPossibleDestinations = () => {
    const {destinations} = this.props.destinations;
    const displayDestinations = Object.keys(destinations).map((destinationId) => {
      const label = destinations[destinationId];
      return {label, value: destinationId};
    });
    displayDestinations.sort((d1, d2) => d1.label.localeCompare(d2.label));
    return [
      { label: "Sélectionner une destination", value: "" },
      ...displayDestinations
    ];
  }

  getEventDays = (start, end) => {
    const startM = moment(start);
    const endM = moment(end);
    const nbDays = endM.diff(startM, 'days');
    const days = [startM.format('YYYY-MM-DD')];
    for(let i = 1; i <= nbDays; i++) {
      days.push(startM.add(1, 'days').format('YYYY-MM-DD'));
    }
    return days;
  }

  getVariantOptions = (eventUserInputs) => {
    let variantOptions = [{ label: "Sélectionner la variante du produit...", value: "" }];
    if (eventUserInputs?.handle) {
      let product = this.props.enabledProducts.find(item => item.handle === eventUserInputs.handle);
      if (product?.variants) {
        product.variants.forEach((item, index) => {
          variantOptions.push({ key: index, label: item.title, value: item.id.toString() });
        });
      }
    }
    return variantOptions;
  }

  render() {
    const userRole = this.props.role;
    const {eventUserInputs, open, saving} = this.state;
    const {modalIsNewEvent, event} = this.props

    let options = [{ label: "Sélectionner un produit...", value: "" }];
    this.props.enabledProducts.forEach((item, index) => {
      options.push({ label: item.title, value: item.handle, index });
    });
    let variantOptions = this.getVariantOptions(eventUserInputs);

    const optionsLocations = [
      {
        label: "Ste-Anne-des-Monts",
        value: "ste-anne-des-monts",
      },
    ];

    const optionsLanguages = [
      {
        label: "FR / EN",
        value: "fren",
      },
      {
        label: "FR",
        value: "fr",
      },
      {
        label: "EN",
        value: "en",
      },
    ];

    const possibleDestinations = this.getPossibleDestinations();
    const eventDays = this.getEventDays(eventUserInputs.startDate, eventUserInputs.endDate);
    const eventDaysEven = eventDays.filter((e, index) => index % 2 === 0);
    const eventDaysOdd = eventDays.filter((e, index) => index % 2 !== 0);

    return (
      <>
        { modalIsNewEvent ?
          (
            <Modal
              open={open}
              onClose={this._closeModal}
              title="Ajouter une nouvelle scéance"
              primaryAction={{
                content: "Enregistrer",
                onAction: this.saveNewEvent,
                loading: saving
              }}
              secondaryActions={[
                {
                  content: "Annuler",
                  onAction: this._closeModal,
                },
              ]}
            >
              <Modal.Section>
                <Form>
                  <FormLayout>
                    <Select
                      label="Produit"
                      options={options}
                      value={eventUserInputs.handle}
                      onChange={this.handleChange("handle")}
                      error={eventUserInputs.errors.missingProduct ? "Un produit est requis" : undefined}
                    />
                    <Select
                      label="Variantes"
                      options={variantOptions}
                      value={eventUserInputs.variantId}
                      onChange={this.handleChange("variantId")}
                    />
                    <Select
                      label="Couleur"
                      options={availableColors}
                      value={eventUserInputs.colorName}
                      onChange={this.handleChange("colorName")}
                    />

                    <FormLayout.Group>
                      <TextField
                        label="Date de début"
                        type="date"
                        value={eventUserInputs.startDate}
                        onChange={this.handleChange("startDate")}
                      />
                        <TextField
                        label="Heure de début"
                        type="time"
                        step={60}
                        value={eventUserInputs.startTime}
                        onChange={this.handleChange("startTime")}
                      />
                      <TextField
                        label="Date de fin"
                        type="date"
                        value={eventUserInputs.endDate}
                        onChange={this.handleChange("endDate")}
                        error={eventUserInputs.errors.endDateBeforeStart ? "Date/heure de fin avant date/heure de début" : undefined}
                      />
                      <TextField
                        label="Heure de fin"
                        type="time"
                        step={60}
                        value={eventUserInputs.endTime}
                        onChange={this.handleChange("endTime")}
                        error={eventUserInputs.errors.endDateBeforeStart ? "Date/heure de fin avant date/heure de début" : undefined}
                      />
                    </FormLayout.Group>

                    <FormLayout.Group>
                      <Select
                        label="Location"
                        options={optionsLocations}
                        // onChange={handleSelectChange}
                        value={eventUserInputs.location}
                        onChange={this.handleChange("location")}
                      />
                      <Select
                        label="Langue(s)"
                        options={optionsLanguages}
                        // onChange={handleSelectChange}
                        value={eventUserInputs.language}
                        onChange={this.handleChange("language")}
                      />
                    </FormLayout.Group>
                  </FormLayout>
                </Form>
              </Modal.Section>
            </Modal>
          ) : (
            <Modal
              open={open}
              onClose={this._closeModal}
              title={event.title}
              primaryAction={{
                content: "Enregistrer",
                onAction: this.saveExistingEvent,
                loading: saving
              }}
              secondaryActions={[
                {
                  content: "Annuler",
                  onAction: this._closeModal,
                },
              ]}
            >
              <Modal.Section>
                <Form>
                  <FormLayout>
                    <Heading>Détails</Heading>
                    {userRole === 'admin' &&
                        <FormLayout.Group>
                          <TextField
                            label="Date de début"
                            type="date"
                            value={eventUserInputs.startDate}
                            onChange={this.handleChange("startDate")}
                          />
                            <TextField
                            label="Heure de début"
                            type="time"
                            step={60}
                            value={eventUserInputs.startTime}
                            onChange={this.handleChange("startTime")}
                          />
                          <TextField
                            label="Date de fin"
                            type="date"
                            value={eventUserInputs.endDate}
                            onChange={this.handleChange("endDate")}
                            error={eventUserInputs.errors.endDateBeforeStart ? "Date/heure de fin avant date/heure de début" : undefined}
                          />
                          <TextField
                            label="Heure de fin"
                            type="time"
                            step={60}
                            value={eventUserInputs.endTime}
                            onChange={this.handleChange("endTime")}
                            error={eventUserInputs.errors.endDateBeforeStart ? "Date/heure de fin avant date/heure de début" : undefined}
                          />
                        </FormLayout.Group>
                    }
                    {userRole === 'admin' && !event.dayoff &&
                      <Select
                        label="Variantes"
                        options={variantOptions}
                        value={eventUserInputs.variantId}
                        onChange={this.handleChange("variantId")}
                      />
                    }
                    {userRole === 'admin' && !event.dayoff &&
                      <Select
                        label="Couleur"
                        options={availableColors}
                        value={eventUserInputs.colorName}
                        onChange={this.handleChange("colorName")}
                      />
                    }
                    {userRole !== 'admin' &&
                      <DescriptionList
                        items={(() => {
                          const items = [
                            {
                              term: "Du",
                              description: event.start,
                            },
                            {
                              term: "Au",
                              description: event.end,
                            }
                          ];
                          if (event.variant !== undefined) {
                            items.unshift(
                              {
                                term: "Date (Variante)",
                                description: event.variant?.title || 'Variante indéfinie'
                              },
                              // {
                              //   term: "Disponibilités",
                              //   description: event.variant ? (event.variant?.inventory_quantity + '/' + event.variant?.old_inventory_quantity) : 'Disponibiltés inconnues'
                              // }
                            );
                          }
                          if (event.orderNumber) {
                            items.push({
                              term: "No. de commande",
                              description: event.orderNumber
                            })
                          }
                          if (event.customer) {
                            items.push({
                              term: "Client",
                              description: `${event.customer.firstName} ${event.customer.lastName} - ${event.customer.email} - ${event.customer.phone}`
                            })
                          }
                          return items;
                        })()}
                      />
                    }

                    {userRole === 'admin' && (event.customer?.firstName || event.participants?.length > 0) &&
                      <div>
                        <Heading>Client(s)</Heading>
                        {event.customer && <p>
                            {event.customer.firstName && <span>{event.customer.firstName} {event.customer.lastName}</span>}
                            {event.customer.email && <span> - <a href={"mailto:" + event.customer.email}>{event.customer.email}</a></span>}
                            {event.customer.phone && <span> - {event.customer.phone}</span>}
                          </p>
                        }
                        {event.participants?.length > 0 &&
                          event.participants.map((p, index) => <p key={index}>{p.name} - <a href={"mailto:" + p.email}>{p.email}</a></p>)
                        }
                      </div>
                    }

                    {userRole === 'admin' && !event.dayoff &&
                      <div>
                        <Heading>No. de commande</Heading>
                        <div style={{ display: "flex", justifyContent: "space-between" }}>{
                          eventUserInputs.manualOrder ? (<>
                            <TextStyle variation="positive">Marqué comme réservé</TextStyle>
                            <TextField
                              placeholder="No. de commande"
                              value={eventUserInputs.orderNumber}
                              onChange={this.handleChange("orderNumber")}
                              error={
                                !eventUserInputs.orderNumber &&
                                "Ce champ ne peut être vide"
                              }
                            ></TextField>
                          </>) : (event.orderNumber ? (<>
                            <Link
                              external
                              url={`https://skichicchocs.com/admin/orders/${event.orderId}`}
                            >
                              # {event.orderNumber}
                            </Link>
                          </>) : (<>
                            <Button onClick={this.toggleManualOrder}>
                              Marquer comme réservé
                            </Button>
                          </>))
                        }</div>
                        <br></br>
                      </div>
                    }

                    {!event.dayoff &&
                      <div>
                        <Heading>Notes</Heading>
                        <TextField
                          readOnly={userRole !== 'admin'}
                          type="text"
                          value={eventUserInputs.notes}
                          onChange={this.handleChange("notes")}
                          multiline={3}
                        />
                      </div>
                    }

                    {eventUserInputs.handle === "ski-hors-piste-guide" &&
                      <div style={{
                        display: "flex",
                        flexDirection: "row",
                        flexWrap: "wrap"
                      }}>
                        <div style={{width: "50%", paddingRight: "10px"}}>
                          {eventDaysEven.map((day) =>
                            <Select
                              key={day}
                              label={`Destination ${day}`}
                              options={possibleDestinations}
                              value={eventUserInputs.destinations[day] || ""}
                              onChange={this.handleChange("destinations", day)}
                            />)}
                        </div>
                        <div style={{width: "50%", paddingLeft: "10px"}}>
                          {eventDaysOdd.map((day) =>
                            <Select
                              key={day}
                              label={`Destination ${day}`}
                              options={possibleDestinations}
                              value={eventUserInputs.destinations[day] || ""}
                              onChange={this.handleChange("destinations", day)}
                            />)}
                        </div>
                      </div>
                    }

                    {userRole === 'admin' && this.props.onRequestEventDeletion &&
                      <Button
                        plain
                        destructive
                        onClick={() => this.props.onRequestEventDeletion(event.id)}
                        style={{ marginTop: 100 }}
                      >
                        Supprimer l'événement
                      </Button>
                    }
                  </FormLayout>
                </Form>
              </Modal.Section>
            </Modal>
          )
        }
      </>
    );
  }

  setSaving = (saving) => {
    this.setState({
      ...this.state,
      saving,
    });
  }

  saveNewEvent = async () => {
    let { eventUserInputs } = this.state;

    this.setSaving(true);

    const errors = this.validateEvent(eventUserInputs);
    if (Object.keys(errors).length > 0) {
      this.setState({
        eventUserInputs: {
          ...eventUserInputs,
          errors
        }
      });
      return;
    }

    let newEvent = this.props.event;
    let product = this.props.enabledProducts.find(
      (product) => product.handle === eventUserInputs.handle
    );

    newEvent.dayoff = product.handle === "non-disponible";
    newEvent.start = `${eventUserInputs.startDate} ${eventUserInputs.startTime}:00`;
    newEvent.end = `${eventUserInputs.endDate} ${eventUserInputs.endTime}:00`;
    newEvent.colorName = eventUserInputs.colorName;
    newEvent.location = eventUserInputs.location;
    newEvent.language = eventUserInputs.language;
    setEventProduct(newEvent, product, eventUserInputs.variantId);
    calculateEventFields(newEvent);

    try {
      await this.persistEvent(newEvent);
      if (this.props.onNewEventSaved) {
        this.props.onNewEventSaved(newEvent);
      }
      this.setSaving(false);
      this._closeModal();
    } catch(e) {
      console.log(e);
    }
  };

  persistEvent = async (event) => {
    try {
      await saveToFirebase(event);
      this.props.showToast("Enregistré!", 2000);
    } catch(e) {
      this.props.showToast(
        "Une erreur est survenue lors de l'enregistrement",
        5000
      );
      throw e;
    }
  }

  saveEventField = (eventId, field, value) => {
    return new Promise((resolve, reject) => {
      database
      .ref(`/events/${DOMAIN_PREFIX}`)
      .child(eventId)
      .child(field)
      .set(value || null, (error) => {
        if (error) {
          reject(error);
        } else {
          resolve();
        }
      });
    });
  }

  saveExistingEvent = async () => {
    let { event } = this.props
    let { eventUserInputs } = this.state;
    let { notes, destinations, manualOrder, orderNumber, startDate,
      endDate, startTime, endTime, colorName, variantId} = eventUserInputs;

    if (event.id) {

      const errors = this.validateEvent(eventUserInputs);
      if (Object.keys(errors).length > 0) {
        this.setState({
          eventUserInputs: {
            ...eventUserInputs,
            errors
          }
        });
        return;
      }

      this.setSaving(true);

      try {
        if((!event.variant && variantId !== "")
            || (event.variant && event.variant.id !== parseInt(variantId))) {
          await this.saveEventField(event.id, 'variantId', variantId);
          setEventProduct(event, event.product, variantId);
        }

        if(event.notes !== notes) {
          await this.saveEventField(event.id, 'notes', notes);
          event.notes = notes;
        }

        if (!_isEqual(event.destinations, destinations)) {
          await this.saveEventField(event.id, 'destinations', destinations);
          event.destinations = destinations;
        }

        if (event.manualOrder !== manualOrder) {
          await this.saveEventField(event.id, 'manualOrder', manualOrder);
          event.manualOrder = manualOrder;
        }

        if (event.orderNumber !== orderNumber) {
          await this.saveEventField(event.id, 'orders', [orderNumber]);
          setEventOrderNumber(event, orderNumber);
        }

        if (event.colorName !== colorName) {
          await this.saveEventField(event.id, 'colorName', colorName);
          event.colorName = colorName;
        }

        const start = `${startDate} ${startTime}:00`;
        if (event.start !== start) {
          await this.saveEventField(event.id, 'start',
              moment(`${startDate} ${startTime}`, "YYYY-MM-DD HH:mm").toISOString());
          event.start = start;
          if (this.props.onUpdateEventStart) {
            this.props.onUpdateEventStart(event, start);
          }
        }

        const end = `${endDate} ${endTime}:00`;
        if (event.end !== end) {
          await this.saveEventField(event.id, 'end',
              moment(`${endDate} ${endTime}`, "YYYY-MM-DD HH:mm").toISOString());
          event.end = end;
          if (this.props.onUpdateEventEnd) {
            this.props.onUpdateEventEnd(event, end);
          }
        }

        calculateEventFields(event);
        this.setSaving(false);
        this.props.showToast("Changements enregistrées avec succès!", 2000);
      }catch(e) {
        this.props.showToast(
          "Une erreur est survenue lors de l'enregistrement",
          5000
        );
        console.log(e);
      }
    }

    this._closeModal();
  };
}
