import React from "react";
import {
  Button,
  Card,
  Form,
  FormLayout,
  Layout,
  Modal,
  Page,
  ResourceList,
  ResourceItem,
  Spinner,
  TextStyle,
  Thumbnail,
} from "@shopify/polaris";
import { database, DOMAIN_PREFIX } from "../firebase";
import { connect } from "react-redux";
import { showToast } from "../redux/actions";
import moment from "moment";
import Flatpickr from "react-flatpickr";
import "flatpickr/dist/themes/light.css";

class DailyRentals extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loaded: false,
      loadingEvents: false,
      modalOpen: false,
      currentRentals: {},
      modalCurrentNode: {
        title: null,
        id: null,
        sku: null,
      },
      modalStartDate: null,
      modalEndDate: null,
      modalDisabledDates: null,
    };
  }

  componentDidMount() {
    database
      .ref(`/rentals/${DOMAIN_PREFIX}`)
      .on("value", this.setCurrentRentals);
  }

  componentDidUpdate() {
    if (this.props.storeData.rentalsProductsLoaded && !this.state.loaded) {
      this.setState({
        loaded: true,
      });
    }
  }

  componentWillUnmount() {
    database.ref(`/rentals/${DOMAIN_PREFIX}`).off();
  }

  _openModal = () => {
    this.setState({ modalOpen: true });
  };
  _closeModal = () => {
    this.setState({ modalOpen: false });
  };

  setCurrentRentals = (snapshot) => {
    let rentals = snapshot.val();
    this.setState({ currentRentals: rentals });
  };

  handleDateChange = (key) => {
    return (value) => {
      if (key === "modalStartDate" || key === "modalEndDate") {
        value = moment(value[0]).format("YYYY-MM-DD");
      } else if (key === "modalDisabledDates") {
        value = value.map((date) => moment(date).format("YYYY-MM-DD"));
      }

      this.setState({ [key]: value });
    };
  };

  handleChange = (key) => {
    return (value) => {
      let { newEventUserInputs } = this.state;
      newEventUserInputs[key] = value;
      this.setState({ newEventUserInputs });
    };
  };

  render() {
    const { rentalsProducts } = this.props.storeData;
    const {
      loaded,
      loadingEvents,
      modalOpen,
      modalCurrentNode,
      modalStartDate,
      modalEndDate,
      modalDisabledDates,
    } = this.state;

    let content = <TextStyle>Chargement...</TextStyle>;
    let modalContent = null;

    if (loaded) {
      let productsResourcesLists = [];
      rentalsProducts.forEach((product) => {
        if (product.variants && product.variants.length > 0) {
          let productResourceList = (
            <Card.Section title={`Produit: ${product.title}`} key={product.id}>
              <ResourceList
                resourceName={{ singular: "variant", plural: "variants" }}
                items={product.variants}
                renderItem={this.renderItem}
                showHeader={false}
              />
            </Card.Section>
          );
          productsResourcesLists.push(productResourceList);
        }
      });

      content = <Card>{productsResourcesLists}</Card>;

      modalContent = (
        <Modal
          open={modalOpen}
          onClose={this._closeModal}
          title={modalCurrentNode.title}
          primaryAction={{
            content: "Enregistrer",
            onAction: this.saveDateRange,
          }}
          secondaryActions={[
            {
              content: "Annuler",
              onAction: this._closeModal,
            },
          ]}
        >
          <Modal.Section>
            <Form>
              <FormLayout>
                <FormLayout.Group>
                  <div style={{ height: "400px" }}>
                    <TextStyle variation="strong">Exemptions</TextStyle>
                    <br></br>
                    <Flatpickr
                      value={modalDisabledDates}
                      options={{
                        inline: true,
                        mode: "multiple",
                        enableTime: false,
                        dateFormat: "Y-m-d",
                        static: true,
                        enable: [{ from: modalStartDate, to: modalEndDate }],
                      }}
                      onChange={this.handleDateChange("modalDisabledDates")}
                      required
                    />
                  </div>

                  <div
                    style={{
                      height: "400px",
                      display: "flex",
                      flexDirection: "column",
                      marginLeft: "20px",
                    }}
                  >
                    <div style={{ height: "80px" }}>
                      <TextStyle variation="strong">À partir du</TextStyle>
                      <br></br>
                      <Flatpickr
                        value={modalStartDate}
                        options={{
                          enableTime: false,
                          dateFormat: "Y-m-d",
                          static: true,
                        }}
                        onChange={this.handleDateChange("modalStartDate")}
                        required
                      />
                    </div>

                    <div style={{ height: "80px" }}>
                      <TextStyle variation="strong">Jusqu'au</TextStyle>
                      <br></br>
                      <Flatpickr
                        value={modalEndDate}
                        options={{
                          enableTime: false,
                          dateFormat: "Y-m-d",
                          static: true,
                        }}
                        onChange={this.handleDateChange("modalEndDate")}
                        required
                      />
                    </div>
                  </div>
                </FormLayout.Group>
              </FormLayout>
            </Form>
          </Modal.Section>
        </Modal>
      );
    }

    return (
      <>
        <Page
          title="Dates des locations"
          titleMetadata={loadingEvents && <Spinner size="small" color="teal" />}
          subtitle="Entrez les dates disponibles à la location"
        >
          <Layout>
            <Layout.Section>{content}</Layout.Section>
          </Layout>
        </Page>
        {modalContent}
      </>
    );
  }

  renderItem = (item) => {
    const { id, title, sku, images } = item;
    const media = (
      <Thumbnail source={images && images.length > 0 ? images[0].src : ""} />
    );

    const { currentRentals } = this.state;
    let currentRentalRangeText = (
      <TextStyle variation="negative">Non disponible</TextStyle>
    );
    let currentDisabledDatesText = null;
    if (sku in currentRentals) {
      const { fromDate, toDate, disabledDates } = currentRentals[sku];
      currentRentalRangeText = `Du ${moment(fromDate, "YYYY-MM-DD").format(
        "D MMM. YYYY"
      )} au ${moment(toDate, "YYYY-MM-DD").format("D MMM. YYYY")}`;
      if (disabledDates && disabledDates.length > 0) {
        currentDisabledDatesText = (
          <TextStyle variation="negative">
            {disabledDates.length} date(s) exemptée(s)
          </TextStyle>
        );
      }
    }

    let skuText = sku ? sku : (
      <TextStyle variation="negative">
        Vous devez entrer un SKU à partir du produit pour cette variante
      </TextStyle>
    );

    return (
      <ResourceItem id={id} media={media}>
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <div>
            <h3>
              <TextStyle variation="strong">
                {title} ({skuText})
              </TextStyle>
            </h3>
            <div>{currentRentalRangeText}</div>
            <div>{currentDisabledDatesText}</div>
          </div>
          <div>
            <Button outline onClick={() => this.editVariant(item)}>
              Modifier
            </Button>
          </div>
        </div>
      </ResourceItem>
    );
  };

  editVariant = (item) => {
    let { currentRentals } = this.state;

    let currentRental = currentRentals[item.sku] || null;

    this.setState({
      modalCurrentNode: item,
      modalOpen: true,
      modalStartDate: currentRental ? currentRental.fromDate : null,
      modalEndDate: currentRental ? currentRental.toDate : null,
      modalDisabledDates:
        currentRental && currentRental.disabledDates
          ? currentRental.disabledDates
          : null,
    });
  };

  saveDateRange = async () => {
    let {
      modalCurrentNode,
      modalStartDate,
      modalEndDate,
      modalDisabledDates,
    } = this.state;

    if (
      modalCurrentNode.sku &&
      modalCurrentNode.id &&
      modalCurrentNode.title &&
      modalStartDate &&
      modalEndDate
    ) {
      let newObj = {
        variantId: modalCurrentNode.id,
        title: modalCurrentNode.title,
        fromDate: modalStartDate,
        toDate: modalEndDate,
        disabledDates: modalDisabledDates,
      };

      database
        .ref(`/rentals/${DOMAIN_PREFIX}`)
        .child(modalCurrentNode.sku)
        .set(newObj, (error) => {
          if (error) {
            this.props.showToast(
              "Il y a eu une erreur. Rafraîchir la page",
              5000
            );
          } else {
            this.props.showToast("Enregistré!", 2000);
            this._closeModal();
          }
        });
    } else {
      this.props.showToast(
        "Il y a eu une erreur. Vérifier que tous les champs sont bien remplis",
        5000
      );
    }
  };
}

const mapStateToProps = (state) => {
  const { storeData } = state;
  return { storeData };
};

export default connect(mapStateToProps, { showToast })(DailyRentals);
