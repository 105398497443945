import { database, functions, DOMAIN_PREFIX } from "../firebase";
import {
  SET_ALL_PRODUCTS,
  SET_ENABLED_PRODUCTS,
  SET_RENTALS_PRODUCTS,
  SET_PRODUCTS,
  SHOW_TOAST,
  HIDE_TOAST,
  SET_USERS,
  SET_USER_ROLE,
  SET_USER_ID,
  SET_DESTINATIONS,
} from "./actionTypes";

export const setProducts = (data) => ({
  type: SET_PRODUCTS,
  payload: {
    data,
  },
});

export const setEnabledProducts = (data) => ({
  type: SET_ENABLED_PRODUCTS,
  payload: {
    data,
  },
});

export const setRentalsProducts = (data) => ({
  type: SET_RENTALS_PRODUCTS,
  payload: {
    data,
  },
});

export const setAllProducts = (data) => ({
  type: SET_ALL_PRODUCTS,
  payload: {
    data,
  },
});

export const setShowToast = (message, duration) => ({
  type: SHOW_TOAST,
  payload: {
    message,
    duration,
  },
});

export const setHideToast = () => ({
  type: HIDE_TOAST,
  payload: {},
});

export const setUsers = (data) => ({
  type: SET_USERS,
  payload: {
    data,
  },
});

export const setUserRole = (role) => ({
  type: SET_USER_ROLE,
  payload: {
    role,
  },
});

export const setUserId = (userId) => ({
  type: SET_USER_ID,
  payload: {
    userId,
  },
});

export const setDestinations = (data) => ({
  type: SET_DESTINATIONS,
  payload: {
    data,
  },
});

export const showToast = (message, duration = 5000) => {
  return function (dispatch) {
    dispatch(setShowToast(message, duration));
  };
};

export const hideToast = () => {
  return function (dispatch) {
    dispatch(setHideToast());
  };
};

export const fetchUsers = (content) => {
  return function (dispatch) {
    database
      .ref("/users/")
      .once("value")
      .then((snapshot) => {
        dispatch(setUsers(snapshot.val()));
      })
      .catch((error) => {
        dispatch(setShowToast("Une erreur est survenue"));
        console.log(error);
      });
  };
};

export const fetchProducts = (content) => {
  return function (dispatch) {
    console.log("Fetching products...");
    var fetchProducts = functions.httpsCallable("fetchProducts");
    const urlParams = "?fields=id,title,images,handle&ids=1867005853796";
    fetchProducts({ urlParams })
      .then(function (result) {
        dispatch(setProducts(result));
      })
      .catch((error) => {
        dispatch(setShowToast("Une erreur est survenue"));
        console.log(error);
      });
  };
};

export const fetchEnabledProducts = (content) => {
  return async function (dispatch) {
    console.log("Fetching ENABLED products...");
    const snapshot = await database
      .ref(`/shops/${DOMAIN_PREFIX}`)
      .child("bookingsProducts")
      .once("value");
    const enabledProductsArray = snapshot.val();

    if (enabledProductsArray && enabledProductsArray.length > 0) {
      var fetchProducts = functions.httpsCallable("fetchProducts");
      const urlParams = `?fields=id,title,images,handle,variants&ids=${enabledProductsArray.join()}`;
      fetchProducts({ urlParams })
        .then((result) => {
          dispatch(setEnabledProducts(result));
        })
        .catch((error) => {
          dispatch(setShowToast("Une erreur est survenue"));
          console.log(error);
        });
    }
  };
};

export const fetchRentalsProducts = (content) => {
  return async function (dispatch) {
    console.log("Fetching RENTALS products...");
    const snapshot = await database
      .ref(`/shops/${DOMAIN_PREFIX}`)
      .child("rentalsProducts")
      .once("value");
    const productsArray = snapshot.val();

    if (productsArray && productsArray.length > 0) {
      var fetchProducts = functions.httpsCallable("fetchProducts");
      const urlParams = `?fields=id,title,images,handle,variants&ids=${productsArray.join()}`;
      fetchProducts({ urlParams })
        .then((result) => {
          dispatch(setRentalsProducts(result));
        })
        .catch((error) => {
          dispatch(setShowToast("Une erreur est survenue"));
          console.log(error);
        });
    }
  };
};

export const fetchAllProducts = (content) => {
  return function (dispatch) {
    console.log("Fetching ALL products...");
    var fetchProducts = functions.httpsCallable("fetchProducts");
    const urlParams = "?fields=id,title,images,handle&limit=250";
    fetchProducts({ urlParams })
      .then(function (result) {
        dispatch(setAllProducts(result));
      })
      .catch((error) => {
        dispatch(setShowToast("Une erreur est survenue"));
        console.log(error);
      });
  };
};

export const fetchDestinations = (content) => {
  return function (dispatch) {
    database
      .ref("/destinations/" + DOMAIN_PREFIX + "/")
      .once("value")
      .then((snapshot) => {
        dispatch(setDestinations(snapshot.val()));
      })
      .catch((error) => {
        dispatch(setShowToast("Une erreur est survenue"));
        console.log(error);
      });
  };
};
